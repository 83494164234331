@if (!loading()) {
  <div class="login-whiteframe">
    <form
      [formGroup]="resetPasswordForm"
      method="POST"
      name="resetPasswordForm"
      (submit)="resetPassword()"
      class="fx-layout-column"
    >
      @if (error) {
        <div class="text-danger">
          Reset token may be invalid or expired
          <br />
          Please use
          <a routerLink="/account/forgotPassword" trackClick="Reset password please use forgot password link clicked"
            >forgot password</a
          >
          again
        </div>
      } @else {
        <h2>Password change</h2>
        <span>Enter a new strong password</span>

        <ih-password-strength
          appearance="outline"
          [formControl]="$any(resetPasswordForm.get('newPassword'))"
          autocomplete="new-password"
          label="New password"
        >
        </ih-password-strength>

        <button
          mat-raised-button
          color="primary"
          type="submit"
          class="reset-password-save-btn"
          [disabled]="updating()"
          trackClick="Reset password Save button clicked"
        >
          Save
        </button>
      }
    </form>
  </div>
} @else {
  <div class="login-whiteframe">
    <div class="fx-layout-row fx-layout-align-center-center">
      <mat-spinner class="spinner" diameter="50"></mat-spinner>
    </div>
  </div>
}
