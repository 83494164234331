import { CommonModule } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  OnDestroy,
  OnInit,
  inject
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  AbstractControl,
  FormsModule,
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  Validators
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatToolbarModule } from '@angular/material/toolbar';
import { Router, RouterModule } from '@angular/router';
import { AdminBaseComponent } from '@ih/admin-base';
import { EMAIL_REGEX } from '@ih/constants';
import { DeviceInfoDialogService, InputDialogService } from '@ih/dialogs';
import { Products } from '@ih/enums';
import { ImageComponent } from '@ih/image';
import { Account, BaseClientConfig, CampaignTagGroup, ImageInfo, Link } from '@ih/interfaces';
import { ToSrcSetPipe } from '@ih/pipes';
import { SafePipe } from '@ih/safe-pipe';
import { ConfirmPasswordDialogService } from '@ih/security';
import { SelectTagsDialogService } from '@ih/select-tags-dialog';
import { AuthService, ConfigService, LazySnackBarService, ScriptService } from '@ih/services';
import { CustomHttpParamCodec } from '@ih/shared';
import { BehaviorSubject, Observable, Subject, firstValueFrom, forkJoin, from, noop, of, timer } from 'rxjs';
import { catchError, debounceTime, map, switchMap, takeUntil } from 'rxjs/operators';
import { AddWebsiteDialogService } from '../add-website-dialog/add-website-dialog.service';
import { ChangeBackgroundImageDialogService } from '../change-background-image-dialog/change-background-image-dialog.service';
import { ChangePasswordDialogService } from '../change-password-dialog/change-password-dialog.service';
import { ChangeProfileImageDialogService } from '../change-profile-image-dialog/change-profile-image-dialog.service';
import { PhoneVerifyDialogService } from '../phone-verify-dialog/phone-verify-dialog.service';
import { ProfilePrivacyDialogService } from '../profile-privacy-dialog/profile-privacy-dialog.service';

@Component({
  standalone: true,
  selector: 'ih-profile-editor',
  templateUrl: './profile-editor.component.html',
  styleUrls: ['./profile-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    RouterModule,

    FormsModule,
    MatButtonModule,
    MatCardModule,
    MatChipsModule,
    MatDialogModule,
    MatDividerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatSlideToggleModule,
    MatToolbarModule,
    ReactiveFormsModule,

    AdminBaseComponent,
    ImageComponent,

    SafePipe,

    ToSrcSetPipe
  ]
})
export class ProfileEditorComponent implements OnInit, OnDestroy {
  @HostBinding('class.ih-profile-editor') hostClass = true;

  private auth = inject(AuthService);
  private router = inject(Router);
  private http = inject(HttpClient);
  private inputDialog = inject(InputDialogService);
  private script = inject(ScriptService);
  private authService = inject(AuthService);
  private profilePrivacyDialog = inject(ProfilePrivacyDialogService);
  private selectTagDialog = inject(SelectTagsDialogService);
  private snackbar = inject(LazySnackBarService);
  private changeProfileImageDialog = inject(ChangeProfileImageDialogService);
  private changeBackgroundImageDialog = inject(ChangeBackgroundImageDialogService);
  private addWebsiteDialog = inject(AddWebsiteDialogService);
  private phoneVerifyDialog = inject(PhoneVerifyDialogService);
  private changePasswordDialog = inject(ChangePasswordDialogService);
  private confirmPasswordDialog = inject(ConfirmPasswordDialogService);
  private deviceInfoDialog = inject(DeviceInfoDialogService);
  private cd = inject(ChangeDetectorRef);
  private config = inject(ConfigService<BaseClientConfig>);

  constructor() {
    this.auth.isAuthenticated$.pipe(takeUntilDestroyed()).subscribe((isAuthenticated) => {
      if (!isAuthenticated) {
        console.debug('ProfileEditorComponent: user is not authenticated. Navigating to home.');
        this.router.navigate(['/home']);
      }
    });
  }

  showLinkToProfile$ = this.config.config$.pipe(map((config) => config.environment.product === Products.App));

  debounceTime = 1000;
  currentUser$ = new BehaviorSubject<Account>({} as Account);

  profileImage$: Observable<ImageInfo | null> = this.currentUser$.pipe(
    switchMap((currentUser) =>
      of(
        currentUser.profileImage
          ? {
              url: currentUser.profileImage.url ?? (currentUser.profileImage as unknown as ImageInfo).url,
              cropData: currentUser.profileImage.cropData,
              blurHash: currentUser.profileImage.blurHash,
              stockPhoto:
                currentUser.profileImage.stockPhoto ?? (currentUser.profileImage as unknown as ImageInfo).stockPhoto
            }
          : null
      )
    )
  );

  backgroundImage$: Observable<ImageInfo | null> = this.currentUser$.pipe(
    switchMap((currentUser) =>
      of(
        currentUser.backgroundImage
          ? {
              url: currentUser.backgroundImage.url ?? (currentUser.backgroundImage as unknown as ImageInfo).url,
              cropData: currentUser.backgroundImage.cropData,
              blurHash: currentUser.backgroundImage.blurHash,
              stockPhoto:
                currentUser.backgroundImage.stockPhoto ??
                (currentUser.backgroundImage as unknown as ImageInfo).stockPhoto
            }
          : null
      )
    )
  );

  private destroy$ = new Subject<void>();

  emailRegex = EMAIL_REGEX;

  nameChanged = false;
  emailChanged = false;
  viewDonation = false;
  updating$ = new BehaviorSubject<boolean>(false);
  hasServiceWorker = false;
  titleChanged = false;
  aboutChanged = false;
  home = false;
  name = '';
  title = '';
  about = '';
  error = '';
  formattedPhone = '';
  accountInfoForm = new UntypedFormGroup({
    fullName: new UntypedFormControl('', [Validators.required, this.customNameValidator, Validators.maxLength(64)]),
    email: new UntypedFormControl(
      '',
      [Validators.required, Validators.pattern(EMAIL_REGEX)],
      this.EmailInUseUserValidator.bind(this)
    ),
    title: new UntypedFormControl('', {
      validators: Validators.maxLength(100),
      updateOn: 'blur'
    }),
    about: new UntypedFormControl('', {
      validators: Validators.maxLength(2000),
      updateOn: 'blur'
    })
  });

  ngOnInit(): void {
    // if the user is not logged in (currentUser is null) then redirect to the home page
    if (!this.authService.currentUser$.getValue()) {
      throw new Error('Member is not logged in');
    }

    this.accountInfoForm
      .get('fullName')!
      .valueChanges.pipe(debounceTime(this.debounceTime), takeUntil(this.destroy$))
      .subscribe(() => this.changingName());

    this.accountInfoForm
      .get('email')!
      .valueChanges.pipe(debounceTime(this.debounceTime), takeUntil(this.destroy$))
      .subscribe(() => this.emailChange());

    this.accountInfoForm
      .get('title')!
      .valueChanges.pipe(debounceTime(this.debounceTime), takeUntil(this.destroy$))
      .subscribe(() => this.titleChange());

    this.accountInfoForm
      .get('about')!
      .valueChanges.pipe(debounceTime(this.debounceTime), takeUntil(this.destroy$))
      .subscribe(() => this.aboutChange());

    const currentUser = this.authService.currentUser$.getValue()!;

    this.viewDonation = currentUser?.roleSecurity.permissions.ViewDonations;

    this.hasServiceWorker = navigator.serviceWorker?.controller != null;

    this.accountInfoForm.setValue(
      {
        fullName: currentUser.fullName,
        email: currentUser.email,
        title: currentUser.title ?? '',
        about: currentUser.about ?? ''
      },
      { emitEvent: false }
    );

    if (currentUser.phone) {
      this.script.loadIntlTelInput().subscribe(() => {
        this.formattedPhone = window.intlTelInputUtils.formatNumber(
          currentUser.phone,
          null,
          window.intlTelInputUtils.numberFormat.NATIONAL
        );
        if (!currentUser.notificationSettings.sms.verified && !this.formattedPhone) {
          this.formattedPhone += ' (unverified)';
        }
        this.cd.markForCheck();
      });
    }
    this.currentUser$.next(currentUser);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  async editEmail(): Promise<void> {
    const dialog = await this.inputDialog.open({
      title: 'Change your email',
      inputLabel: 'Enter your new email address',
      value: this.authService.currentUser$.getValue()?.email,
      validators: [Validators.required, Validators.pattern(EMAIL_REGEX)]
    });

    dialog
      .afterClosed()
      .pipe(
        switchMap((email) => {
          if (email) {
            // confirm the password
            return forkJoin([
              from(
                this.confirmPasswordDialog
                  .open({
                    message:
                      'Please enter your current password to change your email' +
                      '<br><br>' +
                      'A verification email will be sent to your new email address' +
                      '<br>' +
                      `<strong>${email.toLowerCase()}</strong>` +
                      '<br><br>' +
                      'Click the verify link in the email to complete the email change process'
                  })
                  .then((dialog) => firstValueFrom(dialog.afterClosed()))
              ),
              of(email)
            ]);
          }
          return forkJoin([of(null), of(null)] as const);
        }),
        switchMap(([password, email]) => {
          if (password) {
            // update the email
            return this.http.put('/api/account/email', { email, password });
          }
          return of(null);
        })
      )
      .subscribe(
        (data) => {
          if (data) {
            this.snackbar.open('Email change confirmation sent. Please check your email to complete this change');
            this.cancelEmailChange();
          }
        },
        (resp) => {
          switch (resp.status) {
            case 401:
              this.snackbar.open('Unable to save profile info. Password is incorrect.');
              this.accountInfoForm.patchValue({ email: this.authService.currentUser$.getValue()?.email });
              this.cd.markForCheck();
              break;
            default:
              this.snackbar.open('Unable to save profile info. Something went wrong.');
              break;
          }
        }
      );
  }

  customNameValidator(control: AbstractControl) {
    // split the name
    const names = control.value.split(' ');
    const firstName = names[0];
    const lastName = names.length > 1 ? names.slice(1).join(' ') : '';
    if (firstName.length > 32) {
      return { firstNameLength: false };
    }
    if (lastName.length > 32) {
      return { lastNameLength: false };
    }
    return null;
  }

  changingName(): void {
    this.nameChanged = true;
    this.saveAccountInfo('name');
  }

  titleChange(): void {
    this.titleChanged = true;
    this.saveAccountInfo('title');
  }

  aboutChange(): void {
    this.aboutChanged = true;
    this.saveAccountInfo('about');
  }

  cancelEmailChange(): void {
    this.emailChanged = false;
    this.accountInfoForm.patchValue({ email: this.authService.currentUser$.getValue()?.email }, { emitEvent: false });
    this.cd.markForCheck();
  }

  emailChange(): void {
    this.emailChanged = true;
    this.cd.markForCheck();
  }

  async saveAccountInfo(changeField: any): Promise<void> {
    const currentUser = this.authService.currentUser$.getValue()!;
    if (
      this.accountInfoForm.valid &&
      ((changeField === 'name' && this.nameChanged) ||
        (changeField === 'title' && this.titleChanged) ||
        (changeField === 'about' && this.aboutChanged) ||
        (changeField === 'email' && this.emailChanged))
    ) {
      if (
        changeField === 'email' &&
        this.accountInfoForm.get('email')!.value.toLowerCase() !== currentUser.email.toLowerCase() &&
        !this.updating$.value
      ) {
        // email has been changed must confirm user password
        const dialog = await this.confirmPasswordDialog.open({
          message:
            'Please enter your current password to change your email' +
            '<br>' +
            'An email will be sent to your new email address' +
            `<strong>${this.accountInfoForm.get('email')!.value.toLowerCase()}</strong>` +
            '<br>' +
            'Please click the verify link in the email to complete the email change process.'
        });
        dialog.afterClosed().subscribe(
          (password) => {
            if (!password) this.cancelEmailChange();
            else this.saveSettings(password, changeField, { ...currentUser, ...this.accountInfoForm.value });
          },
          () => this.cancelEmailChange()
        );
      } else {
        this.saveSettings(undefined, changeField, { ...currentUser, ...this.accountInfoForm.value });
      }
    }
  }

  saveSettings(password?: string, changeField?: string, user?: Account): void {
    this.updating$.next(true);
    this.cd.markForCheck();
    const accountData = JSON.parse(JSON.stringify(user)) ?? this.currentUser$.value;

    if (password) accountData.currentPassword = password;
    this.snackbar.open(`Updating your ${changeField ?? 'profile'}...`);
    this.http.put<Account>('/api/account', accountData).subscribe(
      (data) => {
        this.updating$.next(false);

        if (changeField === 'name') this.nameChanged = false;
        if (changeField === 'title') this.titleChanged = false;
        if (changeField === 'about') this.aboutChanged = false;
        else if (changeField === 'email') this.emailChanged = false;

        if (changeField === 'email') {
          this.snackbar.open('Email change confirmation sent. Please check your email to complete this change');
          this.cancelEmailChange();
        } else {
          // update our copy of the data
          user = {
            ...user!,
            ...{
              backgroundImage: data.backgroundImage,

              profileImage: data.profileImage
            }
          };

          // if updating the name then update the hidden origin name
          if (changeField === 'name') this.name = user.fullName;
          this.snackbar.open('Profile info updated');
          this.currentUser$.next(user);
        }
      },
      (resp) => {
        this.updating$.next(false);
        // ignore cancelled
        if (resp.xhrStatus === 'abort') {
          return;
        }

        switch (resp.status) {
          case 401:
            this.snackbar.open('Unable to save profile info. Password is incorrect.');
            this.accountInfoForm.patchValue({ email: this.authService.currentUser$.getValue()?.email });
            this.cd.markForCheck();
            break;
          default:
            this.snackbar.open('Unable to save profile info. Something went wrong.');
            break;
        }
      }
    );
  }

  removeWebsite(website: Link): void {
    const currentLinks = this.currentUser$.value.links;
    currentLinks.splice(currentLinks.indexOf(website), 1);
    this.currentUser$.next({ ...this.currentUser$.value, links: currentLinks });
    this.saveSettings(undefined, 'websites', this.currentUser$.value);
  }

  removeTag(group: any, tag: any): void {
    group.tags = group.tags.filter((t: any) => t !== tag);
    this.cd.markForCheck();

    this.http.delete(`/api/account/tags/${tag.campaignTagId}`).subscribe(noop, () => {
      this.snackbar.open('Unable to update tags. Please try again');
    });
  }

  mergeReplace(dst: any, src: any): any {
    for (const i in dst) {
      if (i in src && typeof dst[i] === 'object' && dst[i] !== null && src[i] !== null) {
        dst[i] = this.mergeReplace(dst[i], src[i]);
      }
    }
    for (const j in src) {
      dst[j] = src[j];
    }
    return dst;
  }

  showVerifyPhone(): void {
    this.script.loadIntlTelInput().subscribe(async () => {
      const dialog = await this.phoneVerifyDialog.open({
        phone: this.authService.currentUser$.value!.phone,
        step: this.authService.currentUser$.value!.notificationSettings.sms.verified ? 'change' : undefined,
        verified: this.authService.currentUser$.value!.notificationSettings.sms.verified
      });

      dialog.afterClosed().subscribe(() => {
        // update the phone value in the currentUser copy
        this.currentUser$.next({
          ...this.currentUser$.value,
          phone: this.authService.currentUser$.getValue()!.phone,
          notificationSettings: this.authService.currentUser$.getValue()!.notificationSettings
        });
        if (this.currentUser$.getValue().phone) {
          this.formattedPhone = window.intlTelInputUtils.formatNumber(
            this.currentUser$.getValue().phone,
            null,
            window.intlTelInputUtils.numberFormat.NATIONAL
          );
          if (!this.currentUser$.getValue().notificationSettings.sms.verified) {
            this.formattedPhone += ' (unverified)';
          }
        } else {
          this.formattedPhone = '';
        }
        this.cd.markForCheck();
      });
    });
  }

  showChangePassword(): void {
    this.changePasswordDialog.open();
  }

  async showChangeImage(): Promise<void> {
    const dialog = await this.changeProfileImageDialog.open({
      image: this.authService.currentUser$.value!.profileImage
    });
    dialog.afterClosed().subscribe((result) => {
      if (result) {
        this.currentUser$.next({
          ...this.currentUser$.value,
          profileImage: result
        });
        this.authService.alreadyLoggedIn(this.currentUser$.value);
        this.saveSettings(undefined, 'profile image', this.currentUser$.value);
      }
    });
  }

  async showChangeBackground(): Promise<void> {
    const dialog = await this.changeBackgroundImageDialog.open({
      image: this.authService.currentUser$.value!.backgroundImage
    });
    dialog.afterClosed().subscribe((result) => {
      if (result) {
        this.currentUser$.next({
          ...this.currentUser$.value,
          backgroundImage: result
        });
        this.saveSettings(undefined, 'profile image', this.currentUser$.value);
      }
    });
  }

  showDeviceInfo(): void {
    this.deviceInfoDialog.open();
  }

  async showPrivacyDialog(): Promise<void> {
    const currentUser = this.currentUser$.getValue();
    (await this.profilePrivacyDialog.open(currentUser.privacy)).afterClosed().subscribe((privacy) => {
      if (privacy) {
        this.snackbar.open('Updating your privacy');
        currentUser.privacy = privacy;
        this.cd.markForCheck();
        this.http.put('/api/account/privacy', currentUser.privacy).subscribe(() => {
          this.snackbar.open('Privacy updated');
        });
      }
    });
  }

  async editTagGroup(group: CampaignTagGroup): Promise<void> {
    (await this.selectTagDialog.open({ group })).afterClosed().subscribe((result) => {
      if (result) {
        group.tags = result;
        this.cd.markForCheck();
        this.http.put(`/api/account/tagGroups/${group.campaignTagGroupId}/tags`, result).subscribe({
          next: noop,
          error: () => {
            this.snackbar.open('Unable to update tags');
          }
        });
      }
    });
  }

  async addWebsite(): Promise<void> {
    const dialog = await this.addWebsiteDialog.open();
    dialog.afterClosed().subscribe((result) => {
      if (result) {
        const links = this.currentUser$.value.links ?? [];
        links.push(result);
        this.currentUser$.next({
          ...this.currentUser$.value,
          links
        });
        this.saveSettings(undefined, 'websites', this.currentUser$.value);
      }
    });
  }

  signOutOtherSessions(): void {
    this.http
      .delete('/api/account/sessions/other', {})
      .pipe(
        catchError((err, caught) => {
          this.snackbar
            .open('Something went wrong while trying to do that', 'TRY AGAIN')
            .then((ref) => ref.onAction().subscribe(() => caught));

          throw err;
        })
      )
      .subscribe(() => {
        this.snackbar.open('All other sessions and devices have been logged out');
      });
  }

  private EmailInUseUserValidator(control: AbstractControl): Observable<ValidationErrors | null> {
    if (control.value) {
      return timer(500).pipe(
        switchMap(() =>
          this.http
            .get('/api/account/emailInUseUser', {
              params: new HttpParams({
                encoder: new CustomHttpParamCodec(),
                fromObject: {
                  email: control.value
                }
              })
            })
            .pipe(switchMap((inUse) => (inUse ? of({ emailInUse: true }) : of(null))))
        )
      );
    }

    return of(null);
  }
}
