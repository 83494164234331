import { Injectable } from '@angular/core';
import { type MatDialogRef } from '@angular/material/dialog';
import { LazyDialogService } from '@ih/lazy-dialog';
import { type ChangePasswordDialogComponent } from './change-password-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class ChangePasswordDialogService {
  constructor(private lazyDialog: LazyDialogService) {}

  public async open(): Promise<MatDialogRef<ChangePasswordDialogComponent>> {
    const changePasswordDialogComponent = await import('./change-password-dialog.component').then(
      (m) => m.ChangePasswordDialogComponent
    );

    const dialog = await this.lazyDialog.getDialogService();

    return dialog.open(changePasswordDialogComponent, {
      panelClass: ['change-password-dialog', 'dialog-no-padding', 'dialog-fullscreen'],
      width: undefined,
      height: undefined,
      maxWidth: undefined,
      maxHeight: undefined
    });
  }
}
