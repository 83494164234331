import { AsyncPipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, OnInit, inject } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import { Router } from '@angular/router';
import { AuthService, MatomoService, SignalRService } from '@ih/services';
import { NotificationSettingsComponent } from '../notification-settings/notification-settings.component';

@Component({
  selector: 'ih-account-notification',
  templateUrl: './account-notification.component.html',
  styleUrls: ['./account-notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [AsyncPipe, NgIf, MatTabsModule, NotificationSettingsComponent]
})
export class AccountNotificationComponent implements OnInit {
  @HostBinding('class.ih-account-notification') hostClass = true;

  private authService = inject(AuthService);
  private router = inject(Router);
  private signalr = inject(SignalRService);
  private matomo = inject(MatomoService);

  offline$ = this.signalr.offline$;
  currentUser$ = this.authService.currentUser$;
  homeChannelId = window.campaign.homeChannel.channelId;

  ngOnInit(): void {
    if (!this.authService.isAuthenticated()) {
      console.debug('AccountNotificationComponent: User is not authenticated, redirecting to login page');
      this.router.navigate(['home']);
    }
    this.matomo.trackPageView('Notification settings');
  }

  showProfile(e: any) {
    if (e.index === 0) {
      this.router.navigate(['account', 'profile']);
    }
  }
}
