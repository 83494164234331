import { CurrencyPipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { AfterViewInit, ChangeDetectionStrategy, Component, DestroyRef, inject, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { AdminBaseComponent } from '@ih/admin-base';
import { FilterChipType } from '@ih/enums';
import { Sort } from '@ih/filter-chips';
import {
  Card,
  GroupedResults,
  ListFilterItem,
  Payment,
  SearchResponse,
  Subscription,
  SubscriptionListItem
} from '@ih/interfaces';
import { NoDataComponent } from '@ih/no-data';
import { PriceIntervalComponent } from '@ih/price-interval';
import { LazySnackBarService } from '@ih/services';
import { PaymentListItemComponent } from '@ih/shop';
import { BehaviorSubject, Subject, catchError, map, startWith, switchMap, tap } from 'rxjs';

@Component({
  selector: 'ih-account-billing',
  standalone: true,
  imports: [
    CurrencyPipe,

    MatButtonModule,
    MatIconModule,

    AdminBaseComponent,
    NoDataComponent,
    PaymentListItemComponent,
    PriceIntervalComponent
  ],
  templateUrl: './account-billing.component.html',
  styleUrl: './account-billing.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccountBillingComponent implements AfterViewInit {
  private http = inject(HttpClient);
  private snackbar = inject(LazySnackBarService);
  private destroyRef = inject(DestroyRef);

  loadingSubscriptions = signal<boolean>(false);
  private refreshSubscriptions$ = new Subject<void>();
  subscriptions = signal<SubscriptionListItem[]>([]);

  loadingBillingHistory = signal<boolean>(false);
  billingHistory = signal<Payment<Date>[]>([]);

  loadingPaymentMethods = signal<boolean>(false);
  private refreshPaymentMethods$ = new Subject<void>();
  paymentMethods = signal<Card[]>([]);

  private request$ = new BehaviorSubject<{
    filters: ListFilterItem[];
    groupBy: string | null;
    sort: Sort;
    pageIndex: number;
  }>({
    filters: [],
    groupBy: null,
    sort: { field: 'createdAt', type: FilterChipType.Date, direction: 'desc' },
    pageIndex: 0
  });

  ngAfterViewInit(): void {
    this.refreshPaymentMethods$.pipe(
      startWith(null),
      tap(() => this.loadingPaymentMethods.set(true)),
      switchMap(() => this.http.get<Card[]>('/api/account/paymentMethods')),
      tap({
        next: (cards) => {
          this.paymentMethods.set(cards);
          this.loadingPaymentMethods.set(false);
        },
        error: () => {
          this.loadingPaymentMethods.set(false);
        }
      }),
      catchError((err, caught) => {
        this.snackbar
          .open('Error loading payment methods', 'TRY AGAIN')
          .then((ref) => ref.onAction().subscribe(() => caught));

        throw err;
      }),
      takeUntilDestroyed(this.destroyRef)
    );

    this.refreshSubscriptions$.pipe(
      startWith(null),
      tap(() => this.loadingSubscriptions.set(true)),
      switchMap(() => this.http.get<SearchResponse<Subscription<string>>>('/api/account/subscriptions')),
      map((resp) => ({
        ...resp,
        results: resp.results.map((item) => ({ ...item, createdAt: new Date(item.createdAt) }))
      })),
      tap({
        next: (resp) => {
          const subscriptionItems = resp.results.map((item) => {
            return {
              id: item.campaignUserChargeSubscriptionId,
              createdAt: item.createdAt,
              member: item.member,
              amount: {
                value: item.amount,
                currency: item.currency.code
              },
              status: item.active ? 'Active' : 'Inactive'
            } as SubscriptionListItem;
          });

          this.subscriptions.set(subscriptionItems);
          this.loadingSubscriptions.set(false);
        },
        error: () => {
          this.loadingSubscriptions.set(false);
        }
      }),
      catchError((err, caught) => {
        this.snackbar
          .open('Error loading subscriptions', 'TRY AGAIN')
          .then((ref) => ref.onAction().subscribe(() => caught));

        throw err;
      }),
      takeUntilDestroyed(this.destroyRef)
    );

    this.request$
      .pipe(
        startWith({
          filters: [],
          groupBy: null,
          sort: { field: 'createdAt', type: FilterChipType.Date, direction: 'desc' },
          pageIndex: 0
        }),
        switchMap((request) => {
          this.loadingBillingHistory.set(true);

          const params: { [key: string]: string } = {
            groupBy: '',
            field: request.sort.field,
            direction: request.sort.direction,
            page: request.pageIndex.toString(),
            pageSize: '50'
          };

          return this.http
            .get<SearchResponse<GroupedResults<unknown, Payment>>>('/api/account/billingHistory', {
              params
            })
            .pipe(
              map(
                (resp) =>
                  ({
                    hits: resp.hits,
                    results: resp.results.map((item) => ({
                      group: item.group,
                      results: item.results.map((i) => ({
                        ...i,
                        createdAt: new Date(i.createdAt)
                      }))
                    })),
                    elapsedMiliseconds: resp.elapsedMiliseconds
                  }) as SearchResponse<GroupedResults<unknown, Payment>>
              ),
              tap({
                next: (resp) => {
                  this.loadingBillingHistory.set(false);
                  if (!resp?.results) {
                    return;
                  }

                  this.billingHistory.update((prev) => [...prev, ...resp.results.flatMap((r) => r.results)]);
                },
                error: () => {
                  this.loadingBillingHistory.set(false);
                }
              }),
              catchError((err, caught) => {
                this.snackbar
                  .open('Error loading posts', 'TRY AGAIN')
                  .then((ref) => ref.onAction().subscribe(() => caught));

                throw err;
              }),
              takeUntilDestroyed(this.destroyRef)
            );
        })
      )
      .subscribe();
  }

  openAddPaymentMethodDialog(): void {
    // open dialog
  }
}
