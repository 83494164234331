import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@ih/services';

@Component({
  selector: 'ih-sync-user',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './sync-user.component.html',
  styleUrl: './sync-user.component.css'
})
export class SyncUserComponent implements AfterViewInit {
  private auth = inject(AuthService);

  private router = inject(Router);

  ngAfterViewInit(): void {
    this.auth.syncUser().then(() => {
      setTimeout(() => {
        console.debug('SyncUserComponent.ngAfterViewInit: user synced. Navigating to home.');
        this.router.navigate(['/home']);
      });
    });
  }
}
