import { AsyncPipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, OnDestroy, OnInit, inject } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService, MatomoService, SignalRService } from '@ih/services';
import { Subject } from 'rxjs';
import { ProfileEditorComponent } from '../profile-editor/profile-editor.component';

@Component({
  selector: 'ih-account-profile',
  templateUrl: './account-profile.component.html',
  styleUrls: ['./account-profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [AsyncPipe, NgIf, MatTabsModule, ProfileEditorComponent]
})
export class AccountProfileComponent implements OnInit, OnDestroy {
  @HostBinding('class.ih-account-profile') hostClass = true;

  private authService = inject(AuthService);
  private signalr = inject(SignalRService);
  private matomo = inject(MatomoService);
  private router = inject(Router);
  private activatedRoute = inject(ActivatedRoute);

  offline: boolean = false;
  destroy$ = new Subject<void>();
  currentUser$ = this.authService.currentUser$;
  offline$ = this.signalr.offline$;

  ngOnInit(): void {
    // if you come to this page with a dialog already set then reset the state
    if (this.activatedRoute.snapshot.queryParams['dialog'] !== undefined) {
      this.router.navigate(['account', 'profile'], {
        queryParams: {
          dialog: undefined
        },
        queryParamsHandling: 'merge',
        replaceUrl: true
      });
    }
    if (!this.authService.isAuthenticated()) {
      console.debug('AccountProfileComponent: User is not authenticated, redirecting to login page');
      this.router.navigate(['home']);
    }
    this.matomo.trackPageView('Profile info');
  }

  showNotifications(e: any): void {
    if (e.index === 1) {
      this.router.navigate(['account', 'notifications']);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }
}
