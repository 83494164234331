import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { AppConfig } from '@ih/interfaces';
import { ConfigService } from '@ih/services';
import { map, take } from 'rxjs';

export const RegistrationGuard: CanActivateFn = () => {
  const config = inject(ConfigService<AppConfig>);
  return config.config$.pipe(
    take(1),
    map((appConfig) => !appConfig.isPrivate && !appConfig.registration.disabled)
  );
};
