<div class="account-notifications" *ngIf="(currentUser$ | async) && !(offline$ | async)">
  <div class="md-whiteframe-1dp">
    <mat-tab-group selectedIndex="1" dynamicHeight mat-align-tabs="center" (selectedTabChange)="showProfile($event)">
      <mat-tab label="PROFILE INFO"> </mat-tab>
      <mat-tab label="NOTIFICATIONS">
        <ih-notification-settings></ih-notification-settings>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
