import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  ViewChild,
  inject
} from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { TrackClickDirective } from '@ih/directives';
import { AppConfig } from '@ih/interfaces';
import { ConfigService, LazySnackBarService, MatomoService } from '@ih/services';
import { Observable } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';

@Component({
  standalone: true,
  selector: 'ih-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    ReactiveFormsModule,
    TrackClickDirective,
    RouterModule
  ]
})
export class ForgotPasswordComponent implements AfterViewInit {
  @HostBinding('class.ih-forgot-password') hostClass = true;
  private http = inject(HttpClient);
  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private config = inject(ConfigService<AppConfig>);
  private snackbar = inject(LazySnackBarService);
  private matomo = inject(MatomoService);

  @ViewChild('emailInput') emailInput!: ElementRef<HTMLInputElement>;

  contactEmail$: Observable<string>;
  sending = false;
  forgotPasswordComplete = false;

  forgotPasswordForm = new UntypedFormGroup({
    email: new UntypedFormControl(this.route.snapshot.queryParams['email'], Validators.required)
  });

  constructor() {
    this.contactEmail$ = this.config.config$.pipe(map((appConfig) => appConfig.contactEmail));
    this.matomo.trackPageView('Forgot password');
  }

  ngAfterViewInit(): void {
    this.emailInput.nativeElement.focus();
  }

  send(): void {
    if (this.forgotPasswordForm.valid) {
      this.sending = true;
      this.http
        .post('/api/account/resetPassword', this.forgotPasswordForm.value)
        .pipe(
          catchError((err) => {
            this.snackbar.open('Something went wrong while trying to reset your password.');
            this.sending = false;
            throw err;
          })
        )
        .subscribe(() => {
          this.forgotPasswordComplete = true;
          this.sending = false;

          this.config.config$.pipe(take(1)).subscribe((appConfig) => {
            // if this is a private app, we need to send the user to the login page
            if (appConfig.isPrivate) {
              this.router.navigate(['account', 'login'], {
                queryParams: {
                  notify: 'forgotPassword',
                  email: this.forgotPasswordForm.value.email
                }
              });
              return;
            }

            this.router.navigate(['home'], {
              queryParams: {
                notify: 'forgotPassword',
                email: this.forgotPasswordForm.value.email
              }
            });
          });
        });
    }
  }
}
