import { NgClass, NgIf, NgSwitch, NgSwitchCase } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  OnDestroy,
  inject
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  Validators
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { CompleteProfileDialogService } from '@ih/complete-profile-dialog';
import { EMAIL_REGEX } from '@ih/constants';
import { EmailInUse } from '@ih/enums';
import { Account, AppConfig } from '@ih/interfaces';
import {
  AuthService,
  ConfigService,
  ContentService,
  FirebaseService,
  LazySnackBarService,
  SignalRService
} from '@ih/services';
import { CustomHttpParamCodec } from '@ih/shared';
import { TosDialogService } from '@ih/tos-dialog';
import { VerifyCodeComponent } from '@ih/verify-code';
import { Observable, Subject, of } from 'rxjs';
import { catchError, switchMap, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'ih-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgClass,
    NgIf,
    NgSwitch,
    NgSwitchCase,
    RouterLink,

    MatButtonModule,
    MatCheckboxModule,
    MatDividerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,

    VerifyCodeComponent
  ]
})
export class RegisterComponent implements OnDestroy {
  @HostBinding('class.ih-register') hostClass = true;

  private http = inject(HttpClient);
  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private elementRef = inject(ElementRef);
  private auth = inject(AuthService);
  private firebase = inject(FirebaseService);
  private signalr = inject(SignalRService);
  private content = inject(ContentService);
  private snackbar = inject(LazySnackBarService);
  private config = inject(ConfigService<AppConfig>);
  private cd = inject(ChangeDetectorRef);
  private tosDialog = inject(TosDialogService);
  private completeProfile = inject(CompleteProfileDialogService);

  registrationStep = 'register';

  allowGoogleLogin = false;
  allowFacebookLogin = false;
  allowLinkedInLogin = false;

  contactEmail!: string;

  submitted = false;
  creatingAccount = false;
  registrationFailed = false;
  sendingResetPassword = false;
  forgotPasswordComplete = false;
  codeSent = false;

  registerForm = new UntypedFormGroup({
    email: new UntypedFormControl(
      '',
      [Validators.required, Validators.pattern(EMAIL_REGEX)],
      this.emailInUse.bind(this)
    ),
    tos: new UntypedFormControl(false, Validators.requiredTrue)
  });

  verifyCode = new FormControl('', [Validators.required], [this.validateCode.bind(this)]);

  private readonly destroy$ = new Subject<void>();

  constructor() {
    this.config.config$.pipe(takeUntil(this.destroy$)).subscribe((appConfig) => {
      this.allowFacebookLogin = appConfig.login.facebook;
      this.allowGoogleLogin = appConfig.login.google;
      this.allowLinkedInLogin = appConfig.login.linkedIn;
      this.contactEmail = appConfig.contactEmail;
    });

    if (this.route.snapshot.queryParams['email']) {
      this.registerForm.get('email')!.setValue(this.route.snapshot.queryParams['email']);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  loginWithFacebook(): void {
    window.location.href = '/account/facebookLogin?returnUrl=' + encodeURIComponent(this.getReturnUrl());
  }

  loginWithGoogle(): void {
    window.location.href = '/account/googleLogin?returnUrl=' + encodeURIComponent(this.getReturnUrl());
  }

  loginWithLinkedIn(): void {
    window.location.href = '/account/linkedInLogin?returnUrl=' + encodeURIComponent(this.getReturnUrl());
  }

  private cancelEmailCheck$ = new Subject<void>();
  emailInUse(control: AbstractControl): Observable<ValidationErrors | null> {
    if (!control.value) {
      return of(null);
    }
    return this.http
      .get<EmailInUse>('/api/account/emailInUseRegister', {
        params: new HttpParams({
          encoder: new CustomHttpParamCodec(),
          fromObject: {
            email: control.value
          }
        })
      })
      .pipe(
        takeUntil(this.cancelEmailCheck$),
        switchMap((inUseCode) => {
          this.cd.markForCheck();
          switch (inUseCode) {
            case EmailInUse.Ok:
              return of(null);
            case EmailInUse.EmailInUse:
              return of({
                emailInUse: true
              });
            case EmailInUse.EmailInUseNoPassword:
              return of({
                emailInUseNoPassword: true
              });
            default:
              throw new Error('emailInUseRegister return result that is not implemented - ' + inUseCode);
          }
        })
      );
  }

  private cancelCheck$ = new Subject<void>();
  validateCode(control: AbstractControl): Observable<ValidationErrors | null> {
    if (!control.value) {
      return of(null);
    }

    this.registrationFailed = false;
    if (control.value.length < 6) {
      return of(null);
    }
    // try to submit the code

    this.cancelCheck$.next();

    return this.http
      .post('/api/account/verifyCode', {
        email: this.registerForm.get('email')!.value,
        verifyCode: control.value
      })
      .pipe(
        // don't cancel after we've started creating the account because that could make bad things happen
        takeUntil(this.cancelCheck$),
        switchMap(() => {
          this.creatingAccount = true;
          this.verifyCode.disable({ emitEvent: false });
          // create account
          return this.http.post<Account>('/api/account', {
            ...this.registerForm.value,
            ...{
              verifyCode: control.value
            }
          });
        }),
        switchMap((data) => {
          this.cd.markForCheck();
          this.creatingAccount = false;
          this.auth.alreadyLoggedIn(data);
          this.firebase.syncToken();

          // disconnect from signalr so we can update our userId
          this.signalr
            .disconnect()
            // reconnect to signalr once disconnected
            .then(() => this.signalr.connect())
            // flush content so we can get it as the logged in user
            .then(() => this.content.reset())
            .then(() => this.router.navigateByUrl(this.route.snapshot.queryParams['returnUrl']))
            .then(() => this.completeProfile.open());

          return of(null);
        }),
        catchError((err) => {
          console.error(err);
          this.cd.markForCheck();

          if (this.creatingAccount) {
            this.verifyCode.enable({ emitEvent: false });
            this.creatingAccount = false;
            this.registrationFailed = true;

            return of({
              createFailed: true
            });
          }

          return of({
            codeInvalid: true
          });
        })
      );
  }

  register(): void {
    this.submitted = true;
    if (this.registerForm.valid && !this.registerForm.pending) {
      this.cd.markForCheck();
      this.registrationStep = 'verify';
      this.sendCode().subscribe();
    }
  }

  resetPassword(): void {
    this.cd.markForCheck();
    this.sendingResetPassword = true;
    this.http.post('/api/account/resetPassword', { email: this.registerForm.get('email')!.value }).subscribe(
      () => {
        this.cd.markForCheck();
        this.forgotPasswordComplete = true;
        this.sendingResetPassword = false;
        this.router.navigate(['home'], {
          queryParams: {
            notify: 'forgotPassword',
            email: this.registerForm.get('email')!.value
          }
        });
      },
      () => {
        this.snackbar.open('Something went wrong while trying to reset your password.');
        this.sendingResetPassword = false;
      }
    );
  }

  resendCode(event: Event): void {
    event.preventDefault();

    this.sendCode().subscribe(() => {
      this.cd.markForCheck();
      this.codeSent = true;
      setTimeout(() => {
        this.cd.markForCheck();
        this.codeSent = false;
      }, 10000);
    });
  }

  changeEmail(event: Event): void {
    event.preventDefault();

    this.registrationStep = 'register';
    this.submitted = false;
    requestAnimationFrame(() => {
      const input = this.elementRef.nativeElement.querySelector('input[name=email]');
      input.focus();
      input.select();
    });
  }

  showToS($event: MouseEvent): void {
    $event.preventDefault();
    this.tosDialog.open();
  }

  private sendCode(): Observable<void> {
    this.verifyCode.reset('');
    return this.http.post<void>(
      '/api/account/sendVerify',
      {},
      {
        params: new HttpParams({
          encoder: new CustomHttpParamCodec(),
          fromObject: {
            email: this.registerForm.get('email')!.value
          }
        })
      }
    );
  }

  private getReturnUrl(): string {
    let returnUrl = window.location.href;
    // if the returnUrl is to /account/register then change it to /home
    if (returnUrl.includes('/account/register')) {
      return '/home';
    }

    if (this.route.snapshot.queryParams['returnUrl']) {
      returnUrl += `&returnUrl=${this.route.snapshot.queryParams['returnUrl']}`;
    }
    return returnUrl;
  }
}
