import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, ElementRef, OnInit } from '@angular/core';
import {
  ControlContainer,
  ControlValueAccessor,
  FormControl,
  FormGroupDirective,
  NgForm,
  ReactiveFormsModule
} from '@angular/forms';
import { MatAutocompleteModule, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { ImageComponent } from '@ih/image';
import { Person, Recipient } from '@ih/interfaces';
import { ngValueAccessorProvider, noop } from '@ih/utilities';
import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime, switchMap, tap } from 'rxjs/operators';

/** Error when invalid control is dirty, touched, or submitted. */
class UserRequiredErrorStateMatcher implements ErrorStateMatcher {
  constructor(private parentControl: FormControl) {}

  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      this.parentControl &&
      this.parentControl.invalid &&
      (this.parentControl.dirty || this.parentControl.touched || isSubmitted)
    );
  }
}

@Component({
  standalone: true,
  selector: 'ih-select-user',
  templateUrl: './select-user.component.html',
  styleUrls: ['./select-user.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ngValueAccessorProvider(() => SelectUserComponent)],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
  imports: [
    CommonModule,

    MatAutocompleteModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    ReactiveFormsModule,

    ImageComponent
  ]
})
export class SelectUserComponent implements ControlValueAccessor, OnInit {
  selectedUser$ = new BehaviorSubject<Person | null>(null);

  findUser = new FormControl();

  filteredUsers$!: Observable<Recipient[]>;

  disabled$ = new BehaviorSubject<boolean>(false);

  parentControl!: FormControl;

  matcher!: UserRequiredErrorStateMatcher;

  private originalUser!: Person;

  constructor(
    private http: HttpClient,
    private el: ElementRef,
    private controlContainer: FormGroupDirective
  ) {}

  ngOnInit(): void {
    this.parentControl = this.controlContainer.form.get('from') as FormControl;
    this.matcher = new UserRequiredErrorStateMatcher(this.parentControl);
    this.filteredUsers$ = this.findUser.valueChanges.pipe(
      debounceTime(500),
      tap(() => this.parentControl.markAsTouched()),
      switchMap((value) =>
        this.http.get<Recipient[]>('/api/messages/recipients', {
          params: {
            query: value,
            excludeChannels: true
          }
        })
      )
    );
  }

  writeValue(selectedUser: Person): void {
    this.originalUser = selectedUser;
    this.selectedUser$.next(selectedUser);
  }

  private onChanged: (_modelValue?: Person | null) => void = noop;
  registerOnChange(fn: (_modelValue?: Person | null) => void): void {
    this.onChanged = fn;
  }

  private onTouched = noop;
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled$.next(isDisabled);
  }

  userSelected(event: MatAutocompleteSelectedEvent): void {
    this.selectedUser$.next(event.option.value.person);
    this.onChanged(event.option.value.person);
    this.findUser.setValue(null);
  }

  clearUser(): void {
    this.selectedUser$.next(null);
    this.onChanged(null);
    this.onTouched();
    this.parentControl.markAsUntouched();
    this.parentControl.markAsPristine();
    requestAnimationFrame(() => {
      const el = this.el.nativeElement.querySelector('#findUserInput');
      if (el) {
        el.focus();
      }
    });
  }

  revert(): void {
    this.selectedUser$.next(this.originalUser);
    this.onChanged(this.originalUser);
  }

  focus(): void {
    requestAnimationFrame(() => {
      const el = this.el.nativeElement.querySelector('#findUserInput');
      if (el) {
        el.focus();
      }
    });
  }
}
