import { Injectable } from '@angular/core';
import { type MatDialogRef } from '@angular/material/dialog';
import { ImageDialogOptions } from '@ih/interfaces';
import { LazyDialogService } from '@ih/lazy-dialog';
import { type ChangeProfileImageDialogComponent } from './change-profile-image-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class ChangeProfileImageDialogService {
  constructor(private lazyDialog: LazyDialogService) {}

  public async open(options: ImageDialogOptions): Promise<MatDialogRef<ChangeProfileImageDialogComponent>> {
    const changeProfileImageDialogComponent = await import('./change-profile-image-dialog.component').then(
      (m) => m.ChangeProfileImageDialogComponent
    );

    const dialog = await this.lazyDialog.getDialogService();

    return dialog.open(changeProfileImageDialogComponent, {
      data: options,
      panelClass: ['change-profile-image-dialog', 'basic-dialog'],
      maxWidth: undefined
    });
  }
}
