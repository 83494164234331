import { AsyncPipe, NgClass, NgFor, NgIf } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  OnDestroy,
  OnInit,
  inject
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ReactiveFormsModule, UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { Router, RouterLink } from '@angular/router';
import { AdminBaseComponent } from '@ih/admin-base';
import { ChannelIconComponent } from '@ih/channel-icon';
import { ENVIRONMENT_PRODUCT } from '@ih/constants';
import { ChannelPermission, Products } from '@ih/enums';
import { AppConfig, ChannelNotificationSettings, NotificationSettings } from '@ih/interfaces';
import { PhonePipe } from '@ih/pipes';
import { SafePipe } from '@ih/safe-pipe';
import {
  AuthService,
  ChannelService,
  ConfigService,
  FirebaseService,
  LazySnackBarService,
  ScriptService,
  SecurityService
} from '@ih/services';
import { Subject, combineLatest } from 'rxjs';
import { catchError, filter, map, switchMap, take, takeUntil } from 'rxjs/operators';
import { PhoneVerifyDialogService } from '../phone-verify-dialog/phone-verify-dialog.service';

@Component({
  selector: 'ih-notification-settings',
  templateUrl: './notification-settings.component.html',
  styleUrls: ['./notification-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AsyncPipe,
    NgClass,
    NgFor,
    NgIf,
    RouterLink,

    MatButtonModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatOptionModule,
    MatSelectModule,
    MatSlideToggleModule,
    ReactiveFormsModule,

    AdminBaseComponent,
    ChannelIconComponent,
    PhonePipe,

    SafePipe
  ]
})
export class NotificationSettingsComponent implements OnInit, OnDestroy {
  private auth = inject(AuthService);
  private security = inject(SecurityService);
  private channel = inject(ChannelService);
  private config = inject(ConfigService<AppConfig>);
  private http = inject(HttpClient);
  private snackbar = inject(LazySnackBarService);
  private verifyPhone = inject(PhoneVerifyDialogService);
  private script = inject(ScriptService);
  private cd = inject(ChangeDetectorRef);
  private product = inject(ENVIRONMENT_PRODUCT);
  private firebase = inject(FirebaseService);
  private router = inject(Router);

  @HostBinding('class.ih-notification-settings') hostClass = true;

  hideAddChannels = this.product !== Products.App;

  notificationSettings = new UntypedFormGroup({
    email: new UntypedFormGroup({
      newsletterFrequencyId: new UntypedFormControl(),
      enabled: new UntypedFormControl(),
      comments: new UntypedFormControl(),
      messages: new UntypedFormControl(),
      postApproved: new UntypedFormControl(),
      commentApproved: new UntypedFormControl(),
      newContentSubmitted: new UntypedFormControl()
    }),
    sms: new UntypedFormGroup({
      enabled: new UntypedFormControl(),
      posts: new UntypedFormControl()
    }),
    mobile: new UntypedFormGroup({
      enabled: new UntypedFormControl(),
      comments: new UntypedFormControl(),
      messages: new UntypedFormControl(),
      posts: new UntypedFormControl()
    }),
    desktop: new UntypedFormGroup({
      enabled: new UntypedFormControl(),
      comments: new UntypedFormControl(),
      messages: new UntypedFormControl(),
      posts: new UntypedFormControl()
    }),
    follow: new UntypedFormGroup({
      postsICreate: new UntypedFormControl(),
      postsICommentOn: new UntypedFormControl(),
      newPostsInChannels: new UntypedFormControl(),
      newPostsAnywhere: new UntypedFormControl()
    })
  });

  home!: UntypedFormGroup;

  channelSettings!: UntypedFormArray;
  channelMap = {} as { [channelId: number]: ChannelNotificationSettings };

  userHasPhone$ = this.auth.currentUser$.pipe(map((u) => !!u!.phone));
  userSms$ = this.auth.currentUser$.pipe(map((u) => u!.notificationSettings.sms));

  smsEnabled$ = this.config.config$.pipe(map((c) => c.smsEnabled));

  newContentSubmittedDisabled = !this.security.canChannel(ChannelPermission.PostsPublishScheduleArchive, null);

  private destroy$ = new Subject<void>();

  constructor() {
    this.auth.isAuthenticated$.pipe(takeUntilDestroyed()).subscribe((isAuthenticated) => {
      if (!isAuthenticated) {
        console.debug('NotificationSettingsComponent: user is not authenticated. Navigating to home.');
        this.router.navigate(['/home']);
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  ngOnInit(): void {
    // the builder doesn't have notifyGroups set immediately so we need to wait for it
    combineLatest([this.auth.currentUser$, this.channel.homeChannel$])
      .pipe(
        filter(([u]) => !!u!.notifyGroups),
        take(1)
      )
      .subscribe(([currentUser, homeChannel]) => {
        // wait for homeChannelId to be loaded
        const homeNotificationSettings = currentUser!.notifyGroups.find((c) => c.groupId === homeChannel!.channelId)!;
        this.home = this.getChannelFormGroup();
        this.home.setValue({
          groupId: homeChannel!.channelId,
          name: homeNotificationSettings.name,
          selected: homeNotificationSettings.selected,
          smsAvailable: homeNotificationSettings.smsAvailable,
          smsEnabled: homeNotificationSettings.smsEnabled,
          mobilePushEnabled: homeNotificationSettings.mobilePushEnabled,
          desktopPushEnabled: homeNotificationSettings.desktopPushEnabled,
          emailEnabled: homeNotificationSettings.emailEnabled,
          showOnHome: homeNotificationSettings.showOnHome
        });
        this.home.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
          this.cd.markForCheck();
          this.saveChannelNotificationSettings(this.home);
          this.setDisabledForChannel(this.home, this.notificationSettings.value);
        });

        this.channelMap[homeChannel!.channelId] = homeNotificationSettings;

        this.channelSettings = new UntypedFormArray(
          currentUser!.notifyGroups
            .filter((c) => c.groupId !== homeChannel!.channelId)
            .map((c) => {
              const channel = this.getChannelFormGroup();
              channel.setValue({
                groupId: c.groupId,
                name: c.name,
                selected: c.selected,
                smsAvailable: c.smsAvailable,
                smsEnabled: c.smsEnabled,
                mobilePushEnabled: c.mobilePushEnabled,
                desktopPushEnabled: c.desktopPushEnabled,
                emailEnabled: c.emailEnabled,
                showOnHome: c.showOnHome
              });
              channel.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
                this.cd.markForCheck();
                this.saveChannelNotificationSettings(channel);
                this.setDisabledForChannel(channel, this.notificationSettings.value);
              });
              this.channelMap[c.groupId] = c;
              return channel;
            })
        );

        const userNotificationSettings = currentUser!.notificationSettings;

        // rebuild sms since fields need to be removed to be compatible with reactive forms
        this.notificationSettings.setValue({
          ...this.auth.currentUser$.getValue()!.notificationSettings,
          sms: {
            enabled: userNotificationSettings.sms.enabled,
            posts: userNotificationSettings.sms.posts
          }
        });

        this.notificationSettings.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
          this.cd.markForCheck();
          this.saveSettings();
          this.setDisabledForChannel(this.home, this.notificationSettings.value);
          this.channelSettings.controls.forEach((channel) => {
            this.setDisabledForChannel(channel as UntypedFormGroup, this.notificationSettings.value);
          });
        });

        this.setDisabledForChannel(this.home, this.notificationSettings.value);
        this.channelSettings.controls.forEach((channel) => {
          this.setDisabledForChannel(channel as UntypedFormGroup, this.notificationSettings.value);
        });

        this.cd.markForCheck();
      });
  }

  setDisabledForChannel(channel: UntypedFormGroup, notificationSettings: NotificationSettings): void {
    if (channel.value.selected === false) {
      // channel is muted so disable all notification toggles
      channel.disable({ emitEvent: false });
    } else {
      channel.enable({ emitEvent: false });
    }

    if (!notificationSettings.sms.enabled) {
      channel.get('smsEnabled')!.disable({ emitEvent: false });
    } else {
      channel.get('smsEnabled')!.enable({ emitEvent: false });
    }

    if (!notificationSettings.mobile.enabled) {
      channel.get('smsEnabled')!.disable({ emitEvent: false });
    } else {
      channel.get('smsEnabled')!.enable({ emitEvent: false });
    }
  }

  saveSettings(): void {
    // create a copy of the account data
    this.http
      .put('/api/account/notificationSettings', {
        phone: this.auth.currentUser$.getValue()!.phone,
        userSettings: this.notificationSettings.value
      })
      .pipe(
        catchError((err, caught) => {
          this.snackbar
            .open('Unable to update notification settings', 'TRY AGAIN')
            .then((ref) => ref.onAction().subscribe(() => caught));
          throw err;
        })
      )
      .subscribe(() => {
        this.snackbar.open('Notification settings updated', undefined, { duration: 2000 });
      });
  }

  saveChannelNotificationSettings(channel: UntypedFormGroup): void {
    const originalNotifyGroups = JSON.parse(JSON.stringify(this.auth.currentUser$.getValue()!.notifyGroups));
    const notifyGroups = this.auth.currentUser$.getValue()!.notifyGroups;
    const notifyGroup = notifyGroups.find((c) => c.groupId === channel.value.groupId)!;
    notifyGroup.smsAvailable = channel.value.smsAvailable;
    notifyGroup.smsEnabled = channel.value.smsEnabled;
    notifyGroup.mobilePushEnabled = channel.value.mobilePushEnabled;
    notifyGroup.desktopPushEnabled = channel.value.desktopPushEnabled;
    notifyGroup.emailEnabled = channel.value.emailEnabled;
    notifyGroup.showOnHome = channel.value.showOnHome;
    this.auth.currentUser$.next({
      ...this.auth.currentUser$.getValue()!,
      notifyGroups: [...notifyGroups]
    });

    this.http
      .put(`/api/channels/${channel.value.groupId}/notifications`, notifyGroup)
      .pipe(
        catchError((err, caught) => {
          this.auth.currentUser$.next({
            ...this.auth.currentUser$.getValue()!,
            notifyGroups: [...originalNotifyGroups]
          });
          this.snackbar
            .open('Unable to update notification settings', 'TRY AGAIN')
            .then((ref) => ref.onAction().subscribe(() => caught));
          throw err;
        })
      )
      .subscribe(() => {
        this.snackbar.open('Notification settings updated');
      });
  }

  channelIdentifier(index: number, channel: unknown): string {
    return (channel as UntypedFormControl).value.groupId;
  }

  getChannelFormGroup(): UntypedFormGroup {
    return new UntypedFormGroup({
      groupId: new UntypedFormControl(),
      name: new UntypedFormControl(),
      selected: new UntypedFormControl(),
      smsAvailable: new UntypedFormControl(),
      smsEnabled: new UntypedFormControl(),
      mobilePushEnabled: new UntypedFormControl(),
      desktopPushEnabled: new UntypedFormControl(),
      emailEnabled: new UntypedFormControl(),
      showOnHome: new UntypedFormControl()
    });
  }

  showVerifyPhone(): void {
    this.script
      .loadIntlTelInput()
      .pipe(
        switchMap(async () => {
          const dialog = await this.verifyPhone.open({
            phone: JSON.parse(JSON.stringify(this.auth.currentUser$.getValue()!.phone))
          });

          dialog.afterClosed();
        })
      )
      .subscribe(() => {
        // make sure the account info is up to date
        const userNotificationSettings = this.auth.currentUser$.getValue()!.notificationSettings;

        // rebuild sms since fields need to be removed to be compatible with reactive forms
        this.notificationSettings.setValue(
          {
            ...this.auth.currentUser$.getValue()!.notificationSettings,
            sms: {
              enabled: userNotificationSettings.sms.enabled,
              posts: userNotificationSettings.sms.posts
            }
          },
          { emitEvent: false }
        );
      });
  }

  sendTestMobilePush(): void {
    this.firebase.sendTestPush(true).subscribe();
  }

  sendTestDesktopPush(): void {
    this.firebase.sendTestPush(false).subscribe();
  }
}
