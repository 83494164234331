import { Injectable } from '@angular/core';
import { type MatDialogRef } from '@angular/material/dialog';
import { LazyDialogService } from '@ih/lazy-dialog';
import { type AddWebsiteDialogComponent } from './add-website-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class AddWebsiteDialogService {
  constructor(private lazyDialog: LazyDialogService) {}

  public async open(): Promise<MatDialogRef<AddWebsiteDialogComponent>> {
    const addWebsiteDialogComponent = await import('./add-website-dialog.component').then(
      (m) => m.AddWebsiteDialogComponent
    );
    const dialog = await this.lazyDialog.getDialogService();

    return dialog.open(addWebsiteDialogComponent, {
      panelClass: ['add-website-dialog', 'basic-dialog'],
      width: '560px',
      maxWidth: 'calc(100vw - 32px)'
    });
  }
}
