import { Injectable } from '@angular/core';
import { type MatDialogRef } from '@angular/material/dialog';
import { LazyDialogService } from '@ih/lazy-dialog';
import { ConfirmPasswordDialogOptions, type ConfirmPasswordDialogComponent } from './confirm-password-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class ConfirmPasswordDialogService {
  constructor(private lazyDialog: LazyDialogService) {}

  public async open(options: ConfirmPasswordDialogOptions): Promise<MatDialogRef<ConfirmPasswordDialogComponent>> {
    const confirmPasswordDialogComponent = await import('./confirm-password-dialog.component').then(
      (m) => m.ConfirmPasswordDialogComponent
    );

    const dialog = await this.lazyDialog.getDialogService();

    return dialog.open(confirmPasswordDialogComponent, {
      data: options,
      panelClass: ['confirm-password-dialog', 'basic-dialog'],
      minWidth: 'min(560px, calc( 100vw - 32px))'
    });
  }
}
