<ih-admin-base editorTitle="Billing" svgIcon="credit-card-outline">
  <ng-container button-controls></ng-container>
  <div class="billing-subscription-list-container">
    <h2 class="billing-subscription-list-title">Current plan</h2>
    <div class="billing-subscription-list">
      @if (!loadingSubscriptions() && subscriptions().length === 0) {
        <ih-no-data svgIcon="credit-card-outline">You do not currently have a subscription</ih-no-data>
      } @else {
        @for (item of subscriptions(); track item.id) {
          <div class="billing-subscription-list-item">
            <div class="billing-subscription-list-item-title">{{ item.product.name }}</div>
            <div class="billing-subscription-list-item-description">{{ item.product.description }}</div>
            <div class="billing-subscription-list-item-price">
              {{ item.amount.value | currency: item.amount.currency }}
            </div>
            <ih-price-interval
              [amount]="item.amount.value"
              [currency]="item.amount.currency"
              [interval]="item.product.recurringInterval"
              [intervalCount]="1"
              [taxBehavior]="item.product.taxBehavior"
            ></ih-price-interval>
          </div>
        }
      }
    </div>
  </div>
  <div class="payment-method-container">
    <h2 class="payment-method-title">Payment Method</h2>
    <div class="payment-method">
      @if (!loadingPaymentMethods() && paymentMethods().length === 0) {
        <ih-no-data svgIcon="credit-card-outline">You do not currently have a payment method</ih-no-data>
      } @else {
        @for (item of paymentMethods(); track item.cardId) {}
      }
      <button mat-button class="add-payment-method-button" (click)="openAddPaymentMethodDialog()">
        Add Payment Method
      </button>
    </div>
  </div>
  <div class="billing-history-container">
    <h2 class="billing-history-title">Billing History</h2>
    <div class="billing-history">
      @if (!loadingBillingHistory() && billingHistory().length === 0) {
        <ih-no-data svgIcon="cash-multiple">No billing history found</ih-no-data>
      } @else {
        @for (item of billingHistory(); track item.campaignChargeId) {
          <ih-payment-list-item [item]="item"></ih-payment-list-item>
        }
      }
    </div>
  </div>
</ih-admin-base>
