import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { type MatDialogRef } from '@angular/material/dialog';
import { LazyDialogService } from '@ih/lazy-dialog';
import { type PhoneVerifyDialogComponent, type PhoneVerifyDialogOptions } from './phone-verify-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class PhoneVerifyDialogService {
  constructor(private lazyDialog: LazyDialogService) {}

  public async open(options: PhoneVerifyDialogOptions): Promise<MatDialogRef<PhoneVerifyDialogComponent>> {
    const phoneVerifyDialogComponent: ComponentType<PhoneVerifyDialogComponent> = await import(
      './phone-verify-dialog.component'
    ).then((m) => m.PhoneVerifyDialogComponent);

    const dialog = await this.lazyDialog.getDialogService();

    return dialog.open(phoneVerifyDialogComponent, {
      data: options,
      panelClass: ['phone-verify-dialog', 'basic-dialog'],
      minWidth: 'min(560px, calc( 100vw - 32px))'
    });
  }
}
