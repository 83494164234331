<ng-container *ngIf="{ selectedUser: selectedUser$ | async } as opts">
  <div class="selected-user" *ngIf="opts.selectedUser">
    <label class="from-label">
      <div class="from-label-text">From *</div>
    </label>

    <ih-image class="user-img" [imageInfo]="opts.selectedUser.image" [attr.alt]="opts.selectedUser.fullName" />
    <div class="user-info-container">
      <div class="user-fullname">
        {{ opts.selectedUser.fullName }}
      </div>

      <div class="user-email">
        {{ opts.selectedUser.email }}
      </div>
    </div>
    <button mat-icon-button class="clear-user-btn" (click)="clearUser()" [disabled]="disabled$ | async">
      <mat-icon svgIcon="pencil"></mat-icon>
    </button>
  </div>
  <mat-form-field class="find-user-field" appearance="outline" *ngIf="!opts.selectedUser">
    <ng-content select="mat-label"></ng-content>
    <mat-label>From</mat-label>
    <input
      id="findUserInput"
      required
      type="text"
      placeholder="Find a member by name or email..."
      matInput
      [formControl]="findUser"
      [errorStateMatcher]="matcher"
      [matAutocomplete]="auto"
    />
    <button mat-icon-button matSuffix (click)="revert()" [disabled]="findUser.disabled">
      <mat-icon svgIcon="close"></mat-icon>
    </button>
    <mat-autocomplete
      autoActiveFirstOption
      #auto="matAutocomplete"
      (optionSelected)="userSelected($event)"
      class="welcome-message-from-autocomplete"
    >
      <mat-option *ngFor="let option of filteredUsers$ | async" [value]="option">
        <ih-image
          width="20"
          height="20"
          class="img-circle"
          [imageInfo]="option.person.image"
          [attr.alt]="option.person.fullName"
        />
        <span class="find-user-name">{{ option.name }}</span>
      </mat-option>
    </mat-autocomplete>
    <mat-error *ngIf="parentControl.getError('required')"> Please select a member </mat-error>
  </mat-form-field>
</ng-container>
