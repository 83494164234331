import { Injectable } from '@angular/core';
import { type MatDialogRef } from '@angular/material/dialog';
import { Privacy } from '@ih/interfaces';
import { LazyDialogService } from '@ih/lazy-dialog';

@Injectable({
  providedIn: 'root'
})
export class ProfilePrivacyDialogService {
  constructor(private lazyDialog: LazyDialogService) {}

  async open(privacy: Privacy): Promise<MatDialogRef<unknown>> {
    const profilePrivacyDialogComponent = await import('./profile-privacy-dialog.component').then(
      (m) => m.ProfilePrivacyDialogComponent
    );

    const dialog = await this.lazyDialog.getDialogService();

    const dialogRef = dialog.open(profilePrivacyDialogComponent, {
      data: JSON.parse(JSON.stringify(privacy)),
      panelClass: ['profile-privacy-dialog', 'basic-dialog'],
      maxWidth: undefined
    });

    return dialogRef;
  }
}
