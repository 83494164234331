import { Injectable } from '@angular/core';
import { type MatDialogRef } from '@angular/material/dialog';
import { ImageDialogOptions } from '@ih/interfaces';
import { LazyDialogService } from '@ih/lazy-dialog';
import { type ChangeBackgroundImageDialogComponent } from './change-background-image-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class ChangeBackgroundImageDialogService {
  constructor(private lazyDialog: LazyDialogService) {}

  public async open(options: ImageDialogOptions): Promise<MatDialogRef<ChangeBackgroundImageDialogComponent>> {
    const changeBackgroundImageDialogComponent = await import('./change-background-image-dialog.component').then(
      (m) => m.ChangeBackgroundImageDialogComponent
    );

    const dialog = await this.lazyDialog.getDialogService();

    return dialog.open(changeBackgroundImageDialogComponent, {
      data: options,
      panelClass: ['change-background-image-dialog', 'basic-dialog'],
      maxWidth: undefined
    });
  }
}
