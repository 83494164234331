<!-- TODO: Get the real passkey icon -->
<ih-admin-base
  editorTitle="Profile editor"
  editorIconPath="M11 9C11 10.66 9.66 12 8 12C6.34 12 5 10.66 5 9C5 7.34 6.34 6 8 6C9.66 6 11 7.34 11 9M14 20H2V18C2 15.79 4.69 14 8 14C11.31 14 14 15.79 14 18M22 12V14H13V12M22 8V10H13V8M22 4V6H13V4Z"
>
  <ng-container button-controls>
    <button
      *ngIf="showLinkToProfile$ | async"
      mat-flat-button
      [routerLink]="['/a/', currentUser$.value.campaign_user_id]"
    >
      View profile
    </button>
  </ng-container>

  <form [formGroup]="accountInfoForm" novalidate class="account-info-form">
    <div class="account-info-container fx-layout-column">
      <ng-container *ngIf="currentUser$ | async as currentUser">
        <mat-card
          class="account-info-profile-privacy-card mat-card-container fx-layout-row fx-layout-align-start-center"
        >
          <mat-card-title class="fx-layout-row fx-layout-align-start-center">
            <div class="profile-privacy-icon">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <title>shield-account</title>
                <path
                  d="M12,1L3,5V11C3,16.55 6.84,21.74 12,23C17.16,21.74 21,16.55 21,11V5L12,1M12,5A3,3 0 0,1 15,8A3,3 0 0,1 12,11A3,3 0 0,1 9,8A3,3 0 0,1 12,5M17.13,17C15.92,18.85 14.11,20.24 12,20.92C9.89,20.24 8.08,18.85 6.87,17C6.53,16.5 6.24,16 6,15.47C6,13.82 8.71,12.47 12,12.47C15.29,12.47 18,13.79 18,15.47C17.76,16 17.47,16.5 17.13,17Z"
                />
              </svg>
            </div>

            Profile privacy
          </mat-card-title>
          <div class="fx-flex"></div>
          <button mat-flat-button color="accent" (click)="showPrivacyDialog()">Configure</button>
        </mat-card>

        <mat-card class="account-info-profile-card">
          <div
            class="fx-layout-column fx-layout-align-center-center fx-flex"
            *ngIf="(backgroundImage$ | async) != null"
          >
            <div class="account-info">
              <ng-container
                *ngIf="{
                  backgroundImage: backgroundImage$ | async
                } as accountImg"
              >
                <ih-image
                  *ngIf="accountImg.backgroundImage"
                  class="background-image"
                  [visible]="true"
                  [imageInfo]="accountImg.backgroundImage"
                  alt="Cover photo"
                ></ih-image>
                <img
                  class="background-image"
                  *ngIf="!accountImg.backgroundImage"
                  src="https://ih-cdn.ihub.app/img/default-profile-background.jpg"
                />
                <div class="background-image" *ngIf="!accountImg.backgroundImage"></div>
              </ng-container>
            </div>
          </div>
          <div class="account-info-cover-photo-controls-container fx-layout-row fx-layout-align-start-center">
            <mat-card-title>Cover photo</mat-card-title>
            <div class="fx-flex"></div>
            <button mat-flat-button color="accent" (click)="showChangeBackground()">Set cover photo</button>
          </div>
        </mat-card>

        <mat-card
          *ngIf="{ profileImage: profileImage$ | async } as accountImg"
          class="account-info-profile-image-container fx-layout-row fx-layout-align-start-center"
        >
          <mat-card-title>Profile image</mat-card-title>
          <div class="fx-flex"></div>
          <div class="account-info-image" (click)="showChangeImage()">
            <ih-image
              *ngIf="accountImg.profileImage"
              class="profile-image"
              [imageInfo]="accountImg.profileImage"
              [aspectRatio]="1"
              alt="profile image"
              [title]="currentUser.first_name + ' ' + currentUser.last_name"
            ></ih-image>
          </div>
        </mat-card>

        <mat-card class="account-info-fields-card mat-card-container">
          <mat-form-field appearance="outline">
            <mat-label>Name</mat-label>
            <!-- TODO: need to lift the limit on first and last names -->
            <input
              matInput
              dir="auto"
              matInput
              #name
              maxlength="64"
              name="name"
              class="dark-font-border-color-focus"
              formControlName="fullName"
              type="text"
              required
              data-lpignore="true"
            />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Email</mat-label>
            <input
              matInput
              name="email"
              class="dark-font-border-color-focus"
              formControlName="email"
              type="email"
              required
              readonly
              data-lpignore="true"
              [disabled]="currentUser.password_expired"
            />
            <button
              mat-icon-button
              matSuffix
              class="account-info-email-edit-button"
              *ngIf="currentUser.email"
              (click)="editEmail()"
            >
              <mat-icon svgIcon="pencil"></mat-icon>
            </button>
            <mat-error *ngIf="this.accountInfoForm.get('email')!.errors?.['required']">An email is required</mat-error>
            <mat-error *ngIf="this.accountInfoForm.get('email')!.errors?.['pattern']"
              >Please enter a valid email
            </mat-error>
            <mat-error *ngIf="this.accountInfoForm.get('email')!.errors?.['emailInUse']"
              >Sorry that email address is already in use
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Phone number</mat-label>
            <input
              matInput
              [value]="formattedPhone"
              type="text"
              readonly="true"
              data-lpignore="true"
              name="phone"
              id="phone"
            />
            <button
              mat-button
              matSuffix
              class="set-phone-button"
              *ngIf="!currentUser.phone"
              (click)="showVerifyPhone()"
            >
              SET PHONE
            </button>
            <button
              mat-icon-button
              matSuffix
              class="phone-edit-button"
              *ngIf="currentUser.phone"
              (click)="showVerifyPhone()"
            >
              <mat-icon svgIcon="pencil"></mat-icon>
            </button>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Job title (optional)</mat-label>
            <textarea
              dir="auto"
              matInput
              #title
              maxlength="100"
              name="title"
              class="dark-font-border-color-focus"
              formControlName="title"
              type="text"
              cdkTextareaAutosize
              data-lpignore="true"
            ></textarea>
            <mat-hint matSuffix align="end" class="title-length-hint">{{ title.value.length }} / 100</mat-hint>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>About me (optional)</mat-label>
            <textarea
              dir="auto"
              matInput
              #about
              maxlength="2000"
              name="about"
              class="dark-font-border-color-focus"
              formControlName="about"
              cdkTextareaAutosize
              type="text"
              data-lpignore="true"
            ></textarea>
            <mat-hint align="end">{{ about.value.length }} / 2000</mat-hint>
          </mat-form-field>
        </mat-card>

        <mat-card class="account-info-websites-card mat-card-container">
          <div class="button-row mat-block dark-font-label-focus websites-container">
            <div (click)="addWebsite()" class="fx-layout-row fx-layout-align-start-center">
              <mat-icon svgIcon="link"></mat-icon>
              <div class="tag-group-title fx-layout-align-start-center">Websites</div>
              <span class="fx-flex"></span>
              <button mat-icon-button class="tag-group-button">
                <mat-icon svgIcon="plus"></mat-icon>
              </button>
            </div>

            <mat-divider style="margin-top: 0"></mat-divider>
            <mat-chip-set aria-label="Links">
              <mat-chip
                *ngFor="let link of currentUser.links"
                (removed)="removeWebsite(link)"
                readonly="true"
                class="mat-chip-wrapper-nobox"
              >
                <a [href]="link.link" target="_blank">{{ link.linkText }}</a>
                <button matChipRemove>
                  <mat-icon svgIcon="close-circle"></mat-icon>
                </button> </mat-chip
            ></mat-chip-set>
          </div>
        </mat-card>

        <mat-card class="account-info-user-profile-sections-card mat-card-container">
          <div
            class="button-row mat-block dark-font-label-focus tags-container"
            *ngFor="let group of currentUser.userTags"
          >
            <div (click)="editTagGroup(group)" class="fx-layout-row fx-layout-align-start-center">
              <mat-icon *ngIf="group.svg" [innerHtml]="group.svg | safe: 'html'"></mat-icon>
              <div class="tag-group-title fx-layout-align-start-center">{{ group.name }}</div>
              <span class="fx-flex"></span>
              <button mat-icon-button class="tag-group-button">
                <mat-icon svgIcon="plus"></mat-icon>
              </button>
            </div>
            <mat-divider style="margin-top: 0"></mat-divider>
            <mat-chip-listbox [attr.aria-label]="group.name" [selectable]="false">
              <mat-chip-option
                *ngFor="let tag of group.tags"
                (removed)="removeTag(group, tag)"
                readonly="true"
                class="mat-chip-wrapper-nobox"
              >
                {{ tag.name }}
                <mat-icon matChipRemove svgIcon="close-circle"></mat-icon>
              </mat-chip-option>
            </mat-chip-listbox>
          </div>
        </mat-card>
      </ng-container>
    </div>
  </form>
</ih-admin-base>
