<div [ngSwitch]="registrationStep">
  <div *ngSwitchCase="'register'" class="fx-layout-column fx-layout-align-center">
    <div class="login-header" *ngIf="allowGoogleLogin || allowFacebookLogin || allowLinkedInLogin">Register with</div>
    <button
      mat-raised-button
      class="btn-google-login"
      (click)="loginWithGoogle()"
      *ngIf="allowGoogleLogin"
      trackClick="Register Google button clicked"
    >
      <mat-icon>
        <svg viewBox="0 0 533.5 544.3">
          <path
            d="M533.5 278.4c0-18.5-1.5-37.1-4.7-55.3H272.1v104.8h147c-6.1 33.8-25.7 63.7-54.4 82.7v68h87.7c51.5-47.4 81.1-117.4 81.1-200.2z"
            fill="#4285f4"
          />
          <path
            d="M272.1 544.3c73.4 0 135.3-24.1 180.4-65.7l-87.7-68c-24.4 16.6-55.9 26-92.6 26-71 0-131.2-47.9-152.8-112.3H28.9v70.1c46.2 91.9 140.3 149.9 243.2 149.9z"
            fill="#34a853"
          />
          <path
            d="M119.3 324.3c-11.4-33.8-11.4-70.4 0-104.2V150H28.9c-38.6 76.9-38.6 167.5 0 244.4l90.4-70.1z"
            fill="#fbbc04"
          />
          <path
            d="M272.1 107.7c38.8-.6 76.3 14 104.4 40.8l77.7-77.7C405 24.6 339.7-.8 272.1 0 169.2 0 75.1 58 28.9 150l90.4 70.1c21.5-64.5 81.8-112.4 152.8-112.4z"
            fill="#ea4335"
          />
        </svg>
      </mat-icon>
      <span>Google</span>
    </button>
    <button
      mat-raised-button
      class="btn-facebook-login"
      (click)="loginWithFacebook()"
      *ngIf="allowFacebookLogin"
      trackClick="Register Facebook button clicked"
    >
      <mat-icon>
        <svg viewBox="0 0 24 24">
          <path
            fill="#ffffff"
            d="M17,2V2H17V6H15C14.31,6 14,6.81 14,7.5V10H14L17,10V14H14V22H10V14H7V10H10V6A4,4 0 0,1 14,2H17Z"
          />
        </svg>
      </mat-icon>
      <span>Facebook</span>
    </button>
    <button
      mat-raised-button
      class="btn-linkedin-login"
      (click)="loginWithLinkedIn()"
      *ngIf="allowLinkedInLogin"
      trackClick="Register LinkedIn button clicked"
    >
      <img src="https://ih-cdn.ihub.app/img/In-2C-21px-R.png" />
      <span>LinkedIn</span>
    </button>
    <div class="or-divider" *ngIf="allowFacebookLogin || allowGoogleLogin || allowLinkedInLogin">
      <mat-divider></mat-divider>
      <div>OR</div>
    </div>
    <form [formGroup]="registerForm" (submit)="register()" class="fx-layout-column">
      <h4>Register with your email address</h4>
      <mat-form-field appearance="outline" class="dark-font-label-focus">
        <mat-label> <mat-icon svgIcon="email"></mat-icon> <span>&nbsp;&nbsp;Email address</span> </mat-label>
        <input
          matInput
          formControlName="email"
          class="dark-font-border-color-focus"
          type="email"
          placeholder="you@example.com"
          data-lpignore="true"
          autocomplete="email"
        />
        <mat-error *ngIf="registerForm.get('email')!.hasError('required')">Please enter your email</mat-error>
        <mat-error *ngIf="registerForm.get('email')!.hasError('emailExists')">
          Sorry we can't find that email address. Do you want to
          <a
            routerLink="/account/register"
            trackClick="Register sorry we can't find that email address register link clicked"
            >register</a
          >?
        </mat-error>
        <mat-error
          *ngIf="registerForm.get('email')!.hasError('pattern') || registerForm.get('email')!.hasError('email')"
        >
          Sorry that doesn't look like a valid email
        </mat-error>
        <mat-error *ngIf="registerForm.get('email')!.hasError('emailInUse')">
          Sorry that email is in use. Would you like to
          <a routerLink="/account/login" trackClick="Register sorry that email is in use log in link clicked">Log in</a>
        </mat-error>
        <mat-error *ngIf="registerForm.get('email')!.hasError('emailNoPassword')">
          <span>
            This email address has already started the registration process. You will need to reset your password to log
            in.
          </span>
          <br />
          <a
            href=""
            (click)="resetPassword()"
            trackClick="Register click here to send a password reset email link clicked"
            >Click here</a
          >
          <span> to send a password reset email.</span>
        </mat-error>
      </mat-form-field>
      <mat-checkbox formControlName="tos">
        I accept the
        <a href="" (click)="showToS($event)" trackClick="Register I accept the terms of service link clicked"
          >Terms of service</a
        >
      </mat-checkbox>
      <div class="terms-error" *ngIf="registerForm.get('tos')!.hasError('required') && submitted">
        You must accept the terms of service before you can proceed.
      </div>
      <button
        mat-raised-button
        class="register-btn"
        type="submit"
        color="primary"
        trackClick="Register Register button clicked"
      >
        REGISTER
      </button>
      <div class="text-center mt">
        Already have an account?
        <a
          routerLink="/account/login"
          [queryParams]="{ email: registerForm.get('email')!.value }"
          trackClick="Register Already have an account sign in link clicked"
          >Sign in</a
        >
      </div>
    </form>
  </div>
  <div *ngSwitchCase="'verify'">
    <h2>Verify your email!</h2>
    <div class="verify-info">
      A 6 digit confirmation code has been sent to <strong>{{ registerForm.get('email')!.value }}</strong
      >. The code is temporary and will expire in 30 minutes.
    </div>
    <ih-verify-code
      class="dark-font-label-focus verify-code"
      [ngClass]="{ 'code-invalid': verifyCode.hasError('codeInvalid') }"
      [formControl]="verifyCode"
    >
    </ih-verify-code>
    <div class="invalid-code fx-layout-row fx-layout-align-start-center" *ngIf="verifyCode.hasError('codeInvalid')">
      <mat-icon>
        <svg viewBox="0 0 24 24">
          <path d="M12,2L1,21H23M12,6L19.53,19H4.47M11,10V14H13V10M11,16V18H13V16" />
        </svg>
      </mat-icon>
      <span>Sorry, that code isn't valid. Please try again.</span>
    </div>
    <div class="invalid-code fx-layout-row fx-layout-align-start-center" *ngIf="registrationFailed">
      <mat-icon>
        <svg viewBox="0 0 24 24">
          <path d="M12,2L1,21H23M12,6L19.53,19H4.47M11,10V14H13V10M11,16V18H13V16" />
        </svg>
      </mat-icon>
      <span
        >There was a problem creating your account. Please
        <a
          target="_blank"
          href="mailto:{{ contactEmail }}?subject=Unable to register"
          trackClick="Register problem created your account contact us link clicked"
          >contact us</a
        >.</span
      >
    </div>
    <div class="checking-code fx-layout-row fx-layout-align-start-center" *ngIf="this.verifyCode.pending">
      <mat-spinner mode="indeterminate" diameter="20"></mat-spinner>
      Checking your code...
    </div>
    <div class="checking-code fx-layout-row fx-layout-align-start-center" *ngIf="creatingAccount">
      <mat-spinner mode="indeterminate" diameter="20"></mat-spinner>
      Verified! Creating your account...
    </div>
    <div class="code-sent fx-layout-row fx-layout-align-start-center" *ngIf="codeSent">
      <mat-icon>
        <svg viewBox="0 0 24 24">
          <path
            d="M13 17H17V14L22 18.5L17 23V20H13V17M20 4H4A2 2 0 0 0 2 6V18A2 2 0 0 0 4 20H11V18H4V8L12 13L20 8V14H22V6A2 2 0 0 0 20 4M12 11L4 6H20Z"
          />
        </svg>
      </mat-icon>
      We sent you a new code.
    </div>
    <div class="dialog-note">
      <p>Tip: Make sure to check your spam folder!</p>
      <p *ngIf="!codeSent">
        <strong
          >Didn't get it?
          <a href="" (click)="resendCode($event)" trackClick="Register didn't get it send another code link clicked"
            >Send another code</a
          ></strong
        >
      </p>
      <p>
        <strong
          >Wrong email?
          <a href="" (click)="changeEmail($event)" trackClick="Register wrong email change my email link clicked"
            >Change my email</a
          ></strong
        >
      </p>
    </div>
  </div>
</div>
