<!-- TODO: Get the real passkey icon -->
<ih-admin-base
  editorTitle="Notification settings"
  editorIconPath="M11 10V12H9V14H7V12H5.8C5.4 13.2 4.3 14 3 14C1.3 14 0 12.7 0 11S1.3 8 3 8C4.3 8 5.4 8.8 5.8 10H11M3 10C2.4 10 2 10.4 2 11S2.4 12 3 12 4 11.6 4 11 3.6 10 3 10M16 14C18.7 14 24 15.3 24 18V20H8V18C8 15.3 13.3 14 16 14M16 12C13.8 12 12 10.2 12 8S13.8 4 16 4 20 5.8 20 8 18.2 12 16 12Z"
>
  <ng-container button-controls>
    <!-- no button for you -->
  </ng-container>

  <ng-container *ngIf="!!home">
    <div class="notification-settings-container">
      <!--EMAIL-->
      <div class="section-block">
        <div class="subheader">
          <mat-icon>
            <svg viewBox="0 0 24 24">
              <path
                fill="currentColor"
                d="M20,8L12,13L4,8V6L12,11L20,6M20,4H4C2.89,4 2,4.89 2,6V18A2,2 0 0,0 4,20H20A2,2 0 0,0 22,18V6C22,4.89 21.1,4 20,4Z"
              />
            </svg>
          </mat-icon>
          EMAIL
          <span class="fx-flex"></span>
          <mat-slide-toggle
            [formControl]="$any(notificationSettings.get('email.enabled'))"
            aria-label="Toggle email notifications"
          ></mat-slide-toggle>
        </div>

        <div class="option-row">
          <div class="fx-layout-column fx-flex">
            <span class="notification-item-label">Newsletter frequency</span>
            <span class="notification-item-info fx-hide-lt-sm"> Email newsletter with posts from your channels </span>
          </div>
          <mat-form-field>
            <mat-select [formControl]="$any(notificationSettings.get('email.newsletterFrequencyId'))">
              <mat-option [value]="1">Daily</mat-option>
              <mat-option [value]="2">Weekly</mat-option>
              <mat-option [value]="3">Never</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="option-row">
          <label class="notification-item-label">Email for new comments</label>
          <mat-slide-toggle
            [formControl]="$any(notificationSettings.get('email.comments'))"
            aria-label="Email for new comments"
          >
          </mat-slide-toggle>
        </div>

        <div class="option-row">
          <label class="notification-item-label">Email for new messages</label>
          <mat-slide-toggle
            [formControl]="$any(notificationSettings.get('email.messages'))"
            aria-label="Email for new messages"
          >
          </mat-slide-toggle>
        </div>

        <div class="option-row">
          <label class="notification-item-label">Email when comment approved</label>
          <mat-slide-toggle
            [formControl]="$any(notificationSettings.get('email.commentApproved'))"
            aria-label="Email when comment approved"
          >
          </mat-slide-toggle>
        </div>

        <div class="option-row">
          <label class="notification-item-label">Email when post approved</label>
          <mat-slide-toggle
            [formControl]="$any(notificationSettings.get('email.postApproved'))"
            aria-label="Email when post approved"
          >
          </mat-slide-toggle>
        </div>

        <div class="option-row" *ngIf="!newContentSubmittedDisabled">
          <label class="notification-item-label">Email when new content is submitted</label>
          <mat-slide-toggle
            [formControl]="$any(notificationSettings.get('email.newContentSubmitted'))"
            aria-label="Email when new content is submitted"
          >
          </mat-slide-toggle>
        </div>
      </div>

      <!--SMS TEXT-->
      <div class="section-block" *ngIf="smsEnabled$ | async">
        <ng-container *ngIf="userSms$ | async as sms">
          <div class="subheader">
            <mat-icon>
              <svg id="message-text" viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  d="M20,2H4A2,2 0 0,0 2,4V22L6,18H20A2,2 0 0,0 22,16V4A2,2 0 0,0 20,2M6,9H18V11H6M14,14H6V12H14M18,8H6V6H18"
                />
              </svg>
            </mat-icon>
            <span class="fx-flex">SMS TEXT</span>

            <button
              mat-flat-button
              class="verify-button"
              *ngIf="!sms.verified"
              (click)="showVerifyPhone()"
              [ngClass]="{ verifyButton: (userHasPhone$ | async) && !sms.verified }"
            >
              {{ (userHasPhone$ | async) && !sms.verified ? 'Verify' : 'Add phone' }}
            </button>
          </div>
          <div *ngIf="sms.blocked && sms.blockedPhone">
            <div class="fx-layout-row">
              <div class="fx-layout-column fx-flex">
                <span class="sms-blocked notification-item-info">
                  SMS notifications are disabled. To enable this feature text START to
                  {{ sms.blockedPhone | phone }}
                </span>
              </div>
            </div>
          </div>
          <div class="option-row" *ngIf="!sms.blocked">
            <label class="notification-item-label">SMS text for new posts</label>
            <mat-slide-toggle
              *ngIf="sms.blocked || !sms.verified"
              [disabled]="true"
              matTooltip="Add a phone number to enable SMS"
            >
            </mat-slide-toggle>
            <mat-slide-toggle
              *ngIf="!sms.blocked && sms.verified"
              [formControl]="$any(notificationSettings.get('sms.enabled'))"
              aria-label="SMS text for new posts"
            >
            </mat-slide-toggle>
          </div>
        </ng-container>
      </div>

      <!--Mobile-->
      <div class="section-block">
        <div class="subheader">
          <mat-icon>
            <svg viewBox="0 0 24 24">
              <path
                fill="currentColor"
                d="M17,19H7V5H17M17,1H7C5.89,1 5,1.89 5,3V21A2,2 0 0,0 7,23H17A2,2 0 0,0 19,21V3C19,1.89 18.1,1 17,1Z"
              />
            </svg>
          </mat-icon>
          <span class="fx-flex">MOBILE</span>
          <button mat-flat-button class="verify-button" (click)="sendTestMobilePush()">Send mobile test</button>
        </div>
        <div class="option-row">
          <label class="notification-item-label">Push notification for new comments</label>
          <mat-slide-toggle
            [formControl]="$any(notificationSettings.get('mobile.comments'))"
            aria-label="Push notification for new comments"
          >
          </mat-slide-toggle>
        </div>

        <div class="option-row">
          <label class="notification-item-label">Push notification for new messages</label>
          <mat-slide-toggle
            [formControl]="$any(notificationSettings.get('mobile.messages'))"
            aria-label="Push notification for new messages"
          >
          </mat-slide-toggle>
        </div>

        <div class="option-row">
          <label class="notification-item-label">Push notification for new posts</label>
          <mat-slide-toggle
            [formControl]="$any(notificationSettings.get('mobile.posts'))"
            aria-label="Push notification for new posts"
          >
          </mat-slide-toggle>
        </div>
      </div>

      <!--DESKTOP-->
      <div class="section-block">
        <div class="subheader">
          <mat-icon>
            <svg viewBox="0 0 24 24">
              <path
                fill="currentColor"
                d="M21,16H3V4H21M21,2H3C1.89,2 1,2.89 1,4V16A2,2 0 0,0 3,18H10V20H8V22H16V20H14V18H21A2,2 0 0,0 23,16V4C23,2.89 22.1,2 21,2Z"
              />
            </svg>
          </mat-icon>
          <span class="fx-flex">DESKTOP</span>
          <button mat-flat-button class="verify-button" (click)="sendTestDesktopPush()">Send desktop test</button>
        </div>
        <div class="option-row">
          <label class="notification-item-label">Desktop notification for new comments</label>
          <mat-slide-toggle
            [formControl]="$any(notificationSettings.get('desktop.comments'))"
            aria-label="Desktop notification for new comments"
          >
          </mat-slide-toggle>
        </div>

        <div class="option-row">
          <label class="notification-item-label">Desktop notification for new messages</label>
          <mat-slide-toggle
            [formControl]="$any(notificationSettings.get('desktop.messages'))"
            aria-label="Desktop notification for new messages"
          >
          </mat-slide-toggle>
        </div>

        <div class="option-row">
          <label class="notification-item-label">Desktop notification for new posts</label>
          <mat-slide-toggle
            [formControl]="$any(notificationSettings.get('desktop.posts'))"
            aria-label="Desktop notification for new posts"
          >
          </mat-slide-toggle>
        </div>
      </div>

      <!--COMMENT NOTIFICATIONS-->
      <div class="section-block">
        <div class="subheader">
          <mat-icon>
            <svg viewBox="0 0 24 24">
              <path
                fill="currentColor"
                d="M9 22C8.4 22 8 21.6 8 21V18H4C2.9 18 2 17.1 2 16V4C2 2.9 2.9 2 4 2H20C21.1 2 22 2.9 22 4V16C22 17.1 21.1 18 20 18H13.9L10.2 21.7C10 21.9 9.8 22 9.5 22H9M13 11V5H11V11M13 15V13H11V15H13Z"
              />
            </svg>
          </mat-icon>
          AUTO FOLLOW COMMENTS
        </div>
        <div class="option-row">
          <label class="notification-item-label">Posts I create</label>
          <mat-slide-toggle
            [formControl]="$any(notificationSettings.get('follow.postsICreate'))"
            aria-label="Auto follow comments for posts I create"
          >
          </mat-slide-toggle>
        </div>

        <div class="option-row">
          <label class="notification-item-label">Posts I comment on</label>
          <mat-slide-toggle
            [formControl]="$any(notificationSettings.get('follow.postsICommentOn'))"
            aria-label="Auto follow comments for posts I comment on"
          >
          </mat-slide-toggle>
        </div>

        <div class="option-row">
          <label class="notification-item-label">New posts in channels I join</label>
          <mat-slide-toggle
            [formControl]="$any(notificationSettings.get('follow.newPostsInChannels'))"
            aria-label="Auto follow comments for new posts in channels I join"
          >
          </mat-slide-toggle>
        </div>

        <div class="option-row">
          <label class="notification-item-label">New posts I can access</label>
          <mat-slide-toggle
            [formControl]="$any(notificationSettings.get('follow.newPostsAnywhere'))"
            aria-label="Auto follow comments for new posts I can access"
          >
          </mat-slide-toggle>
        </div>
      </div>

      <!--CHANNEL NOTIFICATION SETTINGS-->
      <div class="section-block channel-block">
        <div class="subheader">
          <mat-icon>
            <svg viewBox="0 0 24 24">
              <path
                fill="currentColor"
                d="M3,5A2,2 0 0,1 5,3H19A2,2 0 0,1 21,5V19A2,2 0 0,1 19,21H5C3.89,21 3,20.1 3,19V5M7,18H9L9.35,16H13.35L13,18H15L15.35,16H17.35L17.71,14H15.71L16.41,10H18.41L18.76,8H16.76L17.12,6H15.12L14.76,8H10.76L11.12,6H9.12L8.76,8H6.76L6.41,10H8.41L7.71,14H5.71L5.35,16H7.35L7,18M10.41,10H14.41L13.71,14H9.71L10.41,10Z"
              />
            </svg>
          </mat-icon>
          <span class="fx-flex">CHANNEL SETTINGS</span>
          <a mat-flat-button class="verify-button" *ngIf="!hideAddChannels" routerLink="/channels">EXPLORE CHANNELS </a>
        </div>
        <mat-accordion>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <ih-channel-icon class="channel-icon" [channel]="channelMap[home.value.groupId]"></ih-channel-icon>
              <span class="notification-channel-name">{{ home.value.name }}</span>
            </mat-expansion-panel-header>

            <!--SMS TEXT-->
            <ng-container *ngIf="smsEnabled$ | async as smsEnabled">
              <div
                class="option-row notification-channel-item fx-layout-row"
                [ngClass]="{ disabled: !notificationSettings.value.sms.enabled || !home.value.selected }"
                *ngIf="home.value.smsAvailable"
              >
                <div class="fx-layout-column fx-flex">
                  <span class="notification-item-label">Receive SMS texts from channel</span>
                  <span class="notification-item-info" *ngIf="!notificationSettings.value.sms.enabled"
                    >Enable sms text notifications above to use this option</span
                  >
                </div>
                <mat-slide-toggle
                  [formControl]="$any(home.get('smsEnabled'))"
                  aria-label="Receive SMS texts from channel"
                >
                </mat-slide-toggle>
              </div>
            </ng-container>

            <!--ANDROID Push Notifications-->
            <div
              class="option-row notification-channel-item fx-layout-row"
              [ngClass]="{ disabled: !notificationSettings.value.mobile.enabled || !home.value.selected }"
            >
              <div class="fx-layout-column fx-flex">
                <label class="notification-item-label">Push notifications from channel</label>
                <span class="notification-item-info" *ngIf="!notificationSettings.value.mobile.enabled"
                  >Enable mobile push notifications text above to use this option</span
                >
              </div>
              <mat-slide-toggle
                [formControl]="$any(home.get('mobilePushEnabled'))"
                aria-label="Push notifications from channel"
              >
              </mat-slide-toggle>
            </div>

            <!--DESKTOP Notifications-->
            <div
              class="option-row notification-channel-item fx-layout-row"
              [ngClass]="{ disabled: !notificationSettings.value.desktop.enabled || !home.value.selected }"
            >
              <div class="fx-layout-column fx-flex">
                <label class="notification-item-label">Desktop notifications from channel</label>
                <span class="notification-item-info" *ngIf="!notificationSettings.value.desktop.enabled"
                  >Enable mobile push notifications text above to use this option</span
                >
              </div>
              <mat-slide-toggle
                [formControl]="$any(home.get('desktopPushEnabled'))"
                aria-label="Desktop notifications from channel"
              >
              </mat-slide-toggle>
            </div>

            <!--EMAIL Notifications-->
            <div
              class="option-row notification-channel-item fx-layout-row"
              [ngClass]="{ disabled: !notificationSettings.value.email.enabled || !home.value.selected }"
            >
              <div class="fx-layout-column fx-flex">
                <label class="notification-item-label">Emails from channel</label>
                <span class="notification-item-info" *ngIf="!notificationSettings.value.email.enabled"
                  >Enable mobile push notifications text above to use this option</span
                >
              </div>
              <mat-slide-toggle [formControl]="$any(home.get('emailEnabled'))" aria-label="Emails from channel">
              </mat-slide-toggle>
            </div>
          </mat-expansion-panel>

          <!--ALL THE OTHER CHANNELS-->
          <mat-expansion-panel *ngFor="let channel of channelSettings.controls; trackBy: channelIdentifier">
            <mat-expansion-panel-header>
              <ih-channel-icon class="channel-icon" [channel]="channelMap[channel.value.groupId]"></ih-channel-icon>
              <span class="notification-channel-name">{{ channel.value.name }}</span>
            </mat-expansion-panel-header>

            <!--Channel content on Home feed-->
            <div class="option-row notification-channel-item fx-layout-row">
              <div class="notification-item-label-div fx-layout-column fx-flex">
                <label class="notification-item-label">Include channel content in Home feed</label>
              </div>
              <mat-slide-toggle
                [formControl]="$any(channel.get('showOnHome'))"
                aria-label="Include channel content in Home feed"
              >
              </mat-slide-toggle>
            </div>

            <!--SMS TEXT-->
            <ng-container *ngIf="smsEnabled$ | async as smsEnabled">
              <div
                class="option-row notification-channel-item fx-layout-row"
                [ngClass]="{ disabled: !notificationSettings.value.sms.enabled || !channel.value.selected }"
                *ngIf="smsEnabled && channel.value.smsAvailable"
              >
                <div class="fx-layout-column fx-flex">
                  <label class="notification-item-label">Receive SMS texts from channel</label>
                  <span class="notification-item-info" *ngIf="!notificationSettings.value.sms.enabled"
                    >Enable sms text above to use this option</span
                  >
                </div>
                <mat-slide-toggle
                  [formControl]="$any(channel.get('smsEnabled'))"
                  aria-label="Receive SMS texts from channel"
                >
                </mat-slide-toggle>
              </div>
            </ng-container>

            <!--ANDROID Push Notifications-->
            <div
              class="option-row notification-channel-item fx-layout-row"
              [ngClass]="{ disabled: !notificationSettings.value.mobile.enabled || !channel.value.selected }"
            >
              <div class="fx-layout-column fx-flex">
                <label class="notification-item-label">Push notifications from channel</label>
                <span class="notification-item-info" *ngIf="!notificationSettings.value.mobile.enabled"
                  >Enable mobile push notifications above to use this option</span
                >
              </div>
              <mat-slide-toggle
                [formControl]="$any(channel.get('mobilePushEnabled'))"
                aria-label="Push notifications from channel"
              >
              </mat-slide-toggle>
            </div>

            <!--DESKTOP Notifications-->
            <div
              class="option-row notification-channel-item fx-layout-row"
              [ngClass]="{ disabled: !notificationSettings.value.desktop.enabled || !channel.value.selected }"
            >
              <div class="fx-layout-column fx-flex">
                <label class="notification-item-label">Desktop notifications from channel</label>
                <span class="notification-item-info" *ngIf="!notificationSettings.value.desktop.enabled"
                  >Enable desktop push notifications above to use this option</span
                >
              </div>
              <mat-slide-toggle
                [formControl]="$any(channel.get('desktopPushEnabled'))"
                aria-label="Desktop notifications from channel"
              >
              </mat-slide-toggle>
            </div>

            <!--EMAIL Notifications-->
            <div
              class="option-row notification-channel-item fx-layout-row"
              [ngClass]="{ disabled: !notificationSettings.value.email.enabled || !channel.value.selected }"
            >
              <div class="fx-layout-column fx-flex">
                <label class="notification-item-label">Emails from channel</label>
                <span class="notification-item-info" *ngIf="!notificationSettings.value.email.enabled"
                  >Enable email notifications above to use this option</span
                >
              </div>
              <mat-slide-toggle [formControl]="$any(channel.get('emailEnabled'))" aria-label="Emails from channel">
              </mat-slide-toggle>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>
  </ng-container>
</ih-admin-base>
