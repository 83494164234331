import { NgIf } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, signal } from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { Account, AppConfig } from '@ih/interfaces';
import { PasswordStrengthComponent } from '@ih/security';
import { AuthService, ConfigService, MatomoService } from '@ih/services';
import { catchError, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'ih-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, RouterLink, MatButtonModule, MatProgressSpinnerModule, ReactiveFormsModule, PasswordStrengthComponent]
})
export class ResetPasswordComponent {
  @HostBinding('class.ih-reset-password') hostClass = true;

  error!: string;

  updating = signal(false);

  resetPasswordForm = new UntypedFormGroup({
    newPassword: new UntypedFormControl('', Validators.required)
  });

  loading = signal(true);

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private auth: AuthService,
    private matomo: MatomoService,
    private cd: ChangeDetectorRef,
    private config: ConfigService<AppConfig>
  ) {
    this.matomo.trackPageView('Account - Password reset');
    console.debug('Calling logout to ensure user is not logged in to another account');
    this.auth.logout({ shouldRedirect: false });

    // check if the token is valid and whether we need to send the user to the registration wizard
    this.http
      .put<Account>(
        '/api/account/resetPassword',
        {},
        {
          params: {
            token: this.route.snapshot.queryParamMap.get('token')!
          }
        }
      )
      .pipe(
        tap(() => {
          this.loading.set(false);
        }),
        catchError((err) => {
          this.cd.markForCheck();
          this.error = err;
          this.loading.set(false);
          throw err;
        })
      )
      .subscribe();
  }

  resetPassword(): void {
    this.error = '';
    if (this.resetPasswordForm.valid) {
      this.cd.markForCheck();
      this.updating.set(true);
      this.http
        .put<Account>('/api/account/resetPassword', this.resetPasswordForm.value, {
          params: {
            token: this.route.snapshot.queryParamMap.get('token')!
          }
        })
        .pipe(
          switchMap((data) => {
            this.updating.set(false);
            this.auth.alreadyLoggedIn(data, true);
            // sync the config
            return this.config.syncConfig();
          }),
          tap(() => {
            this.router.navigate(['home'], {
              queryParams: {
                completeProfile: true
              }
            });
          }),
          catchError((err) => {
            this.cd.markForCheck();
            this.updating.set(false);
            this.error = err;
            throw err;
          })
        )
        .subscribe();
    }
  }
}
