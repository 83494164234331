<form [formGroup]="forgotPasswordForm" *ngIf="!forgotPasswordComplete" class="fx-layout-column">
  <h2>Forgot your password?</h2>
  <div>Enter your email address and we'll send you a link to reset your password.</div>
  <mat-form-field appearance="outline" class="dark-font-label-focus">
    <mat-label> <mat-icon svgIcon="email"></mat-icon> <span>&nbsp;&nbsp;Email address</span> </mat-label>
    <input #emailInput id="email" matInput formControlName="email" class="dark-font-border-color-focus" />
    <mat-error *ngIf="forgotPasswordForm.get('email')!.hasError('required')">Please enter your email</mat-error>
  </mat-form-field>
  <button
    id="submit-button"
    mat-raised-button
    class="forgot-pwd-send-btn"
    color="primary"
    (click)="send()"
    [disabled]="sending"
    type="submit"
    trackClick="Forgot Password Send button clicked"
  >
    SEND
  </button>
  <div class="text-center">
    If you don't receive the email in a few minutes, please check your spam or junk folder.
    <br />
    <br />

    Still need help?
    <a routerLink="/contact" trackClick="Forgot password contact us link clicked">Contact us</a>.
  </div>
</form>
