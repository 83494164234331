<div class="account-settings" *ngIf="(currentUser$ | async) && !(offline$ | async)">
  <div class="md-whiteframe-1dp">
    <mat-tab-group dynamicHeight mat-align-tabs="center" (selectedTabChange)="showNotifications($event)">
      <mat-tab label="PROFILE INFO">
        <ng-template matTabContent>
          <ih-profile-editor> </ih-profile-editor>
        </ng-template>
      </mat-tab>
      <mat-tab label="NOTIFICATIONS"> </mat-tab>
    </mat-tab-group>
  </div>
</div>
